<template>
	<el-drawer v-model="pop" title="" modal-class="mode-class" :with-header="false" direction="rtl" size="25%">
		<div class="full-100 flex-column">
			<PageTitle name="待办事项"></PageTitle>
			
			<div class="flex-1">
				<el-scrollbar height="100%">
					<div class="ptb-12 boderb1-EBEBED pointer" v-for="(v, i) in ToDoList" :key="i">
						<div class="flex-row-center-sb">
							<div class="fz-14 co-1F2533">{{v.title}}</div>
							<div class="flex-row-center-start">
								<div class="point mr-4" v-if="v.is_read == 0"></div>
								<div class="fz-12 co-949CA9">立即处理</div>
								<img class="wh-10 ml-5" src="@/assets/icon_48.png" alt=''>
							</div>
						</div>
						<div class="fz-12 co-949CA9 mt-6">{{v.time}}</div>
					</div>
				</el-scrollbar>
			</div>
		</div>
	</el-drawer>
</template>

<script lang="ts" setup>
	import { ref, defineExpose } from 'vue';

	const pop = ref(false);
	const ToDoList = ref([{
		title: "您有待入库订单待审核",
		time: "2023-11-29 15:10:12",
		is_read: 0,
	}, {
		title: "您有待入库订单待审核",
		time: "2023-11-29 15:10:12",
		is_read: 0,
	}, {
		title: "您有待入库订单待审核",
		time: "2023-11-29 15:10:12",
		is_read: 0,
	}, {
		title: "您有待入库订单待审核",
		time: "2023-11-29 15:10:12",
		is_read: 0,
	}, {
		title: "您有待入库订单待审核",
		time: "2023-11-29 15:10:12",
		is_read: 0,
	}]);
	
	const open = () => {
		pop.value = true;
	};
	const close = () => {
		pop.value = false;
	};

	defineExpose({
		open,
		close,
	})
</script>

<style lang="scss" scoped>
	@import "@/styles/publicscss/publicscss.scss";
	
	.point {
		width: 8px;
		height: 8px;
		background: #F52F3E;
		border-radius: 8px;
	}
</style>