import { createStore } from 'vuex';
import { getCurrentInstance } from 'vue';

export default createStore({
	state: {
		proxy: {},
		RunMode: 1, // 1 开发模式 2正式模式
		WebsocketStatus: false, // websocket连接状态
		WarehouseList: [], // 仓库列表
		AddressList: [], // 省市区列表
		AreaTypeList: [], // 库区类型
		IsRefreshAreaList: false, // 是否刷新库区
		AreaInfo: "", // 库区信息
		IsRefreshRoadway: false, // 是否刷新巷道
		RoadwayInfo: "", // 巷道信息
		IsRefreshShelves: false, // 是否刷新货架
		ShelvesInfo: "", // 巷道信息
		IsRefreshLocationType: false, // 是否刷新库位类型
		LocationTypeList: [], // 库位类型
		IsRefreshTrayType: false, // 是否刷新托盘类型
		TrayTypeList:  [], // 托盘类型
		IsRefreshClassifyList: false, // 是否刷新商品类型
		ClassifyList: [], // 商品类型
		IsRefreshUnit: false, // 是否刷新计量单位
		UnitList: [],
		IsRefreshInOrderType: false, // 是否刷新入库类型
		InOrderTypeList: [], // 入库类型
		IsRefreshOutOrderType: false, // 是否刷新出库类型
		OutOrderTypeList: [], // 出库类型
		IsRefreshPdaUser: false, // 是否刷新PDA人员
		PdaUserList: [], // PDA人员
		IsRefreshOperatorList: false, // 是否刷新操作人员列表
		OperatorList: [], // 操作人员列表
		OperatorListInfo: "",
		IsRefreshSupplierUser: false, // 是否刷新供应商列表
		SupplierUserList: [], // 供应商列表
		AdminUserList: [], // 盘点创建人列表
		SearchTitle: "", // 库位平面图搜索标题
	},
	getters: {

	},
	mutations: {
		SetMode(state, params) {
			state.RunMode = params;
		},
		// 设置websocket连接状态
		SetWebsocketStatus(state : any, status : any) {
			state.WebsocketStatus = status;
		},
		// 获取各种列表
		GetAllList(state : any, params : any) {
			if (params.type == -1) {
			} else if (params.type == 1) {
				state.WarehouseList = params.data;
			} else if (params.type == 2) {
				state.proxy.$api.tokenRequest({
					url: '/admin/warehouse/listByType',
					data: {
						type: 1,
					},
					success: (res : any) => {
						state.WarehouseList = res.data;
					},
				});
			} else if (params.type == 3) {
				state.AddressList = params.data;
			} else if (params.type == 4) {
				state.AreaTypeList = params.data;
			} else if (params.type == 5) {
				state.proxy.$api.tokenRequest({
					url: '/admin/type',
					data: {
						type: 1,
						is_page: 0,
					},
					success: (res : any) => {
						state.AreaTypeList = res.data;
					},
				});
			} else if (params.type == 6) {
				state.AreaInfo = {
					id: params.id,
					list: params.list,
				};
				state.IsRefreshAreaList = false;
			} else if (params.type == 7) {
				state.IsRefreshAreaList = true;
			} else if (params.type == 8) {
				state.IsRefreshRoadway = true;
			} else if (params.type == 9) {
				state.RoadwayInfo = {
					wid: params.wid,
					aid: params.aid,
					list: params.list,
				};
				state.IsRefreshRoadway = false;
			} else if (params.type == 10) {
				state.IsRefreshShelves = true;
			} else if (params.type == 11) {
				state.ShelvesInfo = {
					wid: params.wid,
					aid: params.aid,
					rid: params.rid,
					list: params.list,
				};
				state.IsRefreshShelves = false;
			} else if (params.type == 12) {
				state.IsRefreshLocationType = true;
			} else if (params.type == 13) {
				state.LocationTypeList = params.data;
				state.IsRefreshLocationType = false;
			} else if (params.type == 14) {
				state.IsRefreshTrayType = true;
			} else if (params.type == 15) {
				state.TrayTypeList = params.data;
				state.IsRefreshTrayType = false;
			} else if (params.type == 16) {
				state.IsRefreshClassifyList = true;
			} else if (params.type == 17) {
				state.ClassifyList = params.data;
				state.IsRefreshClassifyList = false;
			} else if (params.type == 18) {
				state.IsRefreshUnit = true;
			} else if (params.type == 19) {
				state.UnitList = params.data;
				state.IsRefreshUnit = false;
			} else if (params.type == 20) {
				state.IsRefreshInOrderType = true;
			} else if (params.type == 21) {
				state.InOrderTypeList = params.data;
				state.IsRefreshInOrderType = false;
			} else if (params.type == 22) {
				state.IsRefreshOutOrderType = true;
			} else if (params.type == 23) {
				state.OutOrderTypeList = params.data;
				state.IsRefreshOutOrderType = false;
			} else if (params.type == 31) {
				state.IsRefreshPdaUser = true;
			} else if (params.type == 24) {
				state.PdaUserList = params.data;
				state.IsRefreshPdaUser = false;
			} else if (params.type == 25) {
				state.IsRefreshOperatorList = true;
			} else if (params.type == 26) {
				state.OperatorList = params.data;
				state.IsRefreshOperatorList = false;
			} else if (params.type == 27) {
				state.OperatorListInfo = {
					wid: params.wid,
					list: params.list,
				};
			} else if (params.type == 28) {
				state.IsRefreshSupplierUser = true;
			} else if (params.type == 29) {
				state.SupplierUserList = params.data;
				state.IsRefreshSupplierUser = false;
			} else if (params.type == 30) {
				state.AdminUserList = params.data;
			}
		},
		// 设置main.ts
		SetMainTs(state : any) {
			if (!state.proxy.$api) {
				const proxy = getCurrentInstance() as any;
				state.proxy = proxy.appContext.config.globalProperties;
			}
		},
		// 库位平面图搜索商品
		SetSearchTitle(state : any, status : any) {
			state.SearchTitle = status;
		},
	},
	actions: {
		// 获取全局实例方法
		async GetMainTs({ commit }) {
			await commit("SetMainTs");
		}
	},
	modules: {
	}
})