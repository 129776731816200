import config from "./config";
import axios from "axios";
import { ElLoading, ElMessage } from 'element-plus';
import { ref } from "vue";
import router from '@/router/index';


const token = ref<any>();
const loading = ref<any>();

// 调用接口封装
const request = (params : any) => {
	if (!params.method) {
		params.method = config.method;
	}
	if (!params.url) {
		console.log("未配置请求接口路径");
		return false
	}
	params.method = params.method.toLocaleUpperCase(); // 请求类型
	params.url = config.request_host + params.url;
	if (params.isLoading) {
		loading.value = ElLoading.service({
			lock: true,
			text: params.isLoading,
			spinner: 'el-icon-loading',
			background: 'rgba(0, 0, 0, 0.5)'
		});
	}
	if (params.method == 'GET') {
		params.params = params.data || {}
	}
	axios(params).then((res : any) => {
		if (params.isLoading) {
			loading.value.close();
		}
		if (res.data.code == 200) {
			params.success && typeof params.success === 'function' && params.success(res.data);
		} else {
			if (res.data.code == 40002) {
				ElMessage.error(res.data.message);
				localStorage.clear();
				router.replace('/');
			} else {
				if (res.data.code != 80020) {
					ElMessage.error(res.data.message);
				}
			}
			params.fail && typeof params.fail === 'function' && params.fail(res.data);
		}
	}).catch((res : any) => {
		if (params.isLoading) {
			loading.value.close();
		}
		ElMessage.error(res.message);
		params.fail && typeof params.fail === 'function' && params.fail(res);
	});
}

const tokenRequest = (params : any) => {
	token.value = localStorage.getItem('token');
	// token.value = "pUHSx3gc2SNq0Ewvzzo9Y0ICC3kYmyIBxuIEFS7JbaFmD5PJ/D9B246ZiPW/2IScWSpUjbGyg2PftUCyDIbxXOmMYJlwvcZPbDbRw2s88f07qCXVI6ePvNvpk0/uR3FHkrtdfACRoO+lJQcQtcPh0SGwUfyuV7013rnXgFa2KHK/L7B9WFn2J5tGxO0nxxa0SkkN+6HD78dvGg7GIoE22dx0URYSbJXhwAmX3AVheJIK3vGQBNqwxpg3KkF6GGyFuMn+vAFleJiOoDAl7mLrCbJIm2ECHA13I4MPAp8CLMYIS0GLl9LOvxvrkGcxDu/Lf/EfXlhwyjv24dGdZlrNyXt5sePkvqVakWUx1ypBdBtLWTvzOOZ50X/moalF7h6xlvkTqWRKlIhBZeESetIEFaLX6nnNij5dZV7G9fSW2LpCvp9gAc8eVEzRpZHNBRr638glC2iNEJkM5KCvbngERRfBUoHWKX5m+yosZuSE/BZsT8QHY5GZOfbPjTg2BbC/XEQacgas1EYUZDfY1y/xC8izitUwvCO53s+vFB0kuqqmySZmKc1ewJXnQuVveROw6Edb+DHja34oG3QmZH1mIztzRsQ416tYFjUcwf0P+cEl2wS2F8FhhZDGL1ojgHw4mJOhGskvJ9ykCNHbnvNsj06x8TU1CEp+D0iiRcmbvvk=";
	if (!token.value) {
		console.log("未获取到token,请检查登录或刷新token部分的代码");
		router.replace('/');
		return false
	}
	params.headers = {
		"Authorization": token.value,
		"version": "v1",
	};
	request(params);
}

export default {
	request: request,
	tokenRequest: tokenRequest,
}