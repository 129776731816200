import { ref } from 'vue';

// 获取当前时间
const getCurrentTime = function (type = "ms", connector = 1) {
	const mydate : any = new Date();
	const year : any = mydate.getFullYear();
	let month : any = mydate.getMonth() + 1;
	let day : any = mydate.getDate();
	let hour : any = mydate.getHours();
	let minute : any = mydate.getMinutes();
	let second : any = mydate.getSeconds();
	const time : any = mydate.getTime();
	// 格式化
	month = month < 10 ? "0" + month : month;
	day = day < 10 ? "0" + day : day;
	hour = hour < 10 ? "0" + hour : hour;
	minute = minute < 10 ? "0" + minute : minute;
	second = second < 10 ? "0" + second : second;
	let str = '';
	if (type == "year") {
		// 年
		str = year;
		if (connector == 2) {
			str += "年";
		}
	} else if (type == "onlyyear") {
		// 只有年
		str = year;
	} else if (type == "onlymonth") {
		// 只有月
		str = month;
	} else if (type == "onlyday") {
		// 只有日
		str = day;
	} else if (type == "onlyhour") {
		// 只有时
		str = hour;
	} else if (type == "onlymin") {
		// 只有分
		str = minute;
	} else if (type == "month") {
		// 月
		if (connector == 1) {
			str = [year, month].join("-");
		} else if (connector == 3) {
			str = [year, month].join("/");
		} else if (connector == 2) {
			str = year + "年" + month + "月";
		}
	} else if (type == "day") {
		// 日
		if (connector == 1) {
			str = [year, month, day].join("-");
		} else if (connector == 3) {
			str = [year, month, day].join("/");
		} else if (connector == 2) {
			str = year + "年" + month + "月" + day + "日";
		}
	} else if (type == "min") {
		// 分
		if (connector == 1) {
			str = [year, month, day].join("-") + " " + [hour, minute].join(":");
		} else if (connector == 3) {
			str = [year, month, day].join("/") + " " + [hour, minute].join(":");
		} else if (connector == 2) {
			str = year + "年" + month + "月" + day + "日" + " " + [hour, minute].join(":");
		}
	} else if (type == "sec") {
		// 秒
		if (connector == 1) {
			str = [year, month, day].join("-") + " " + [hour, minute, second].join(":");
		} else if (connector == 3) {
			str = [year, month, day].join("/") + " " + [hour, minute, second].join(":");
		} else if (connector == 2) {
			str = year + "年" + month + "月" + day + "日" + " " + [hour, minute, second].join(":");
		}
	} else if (type == "ms") {
		str = time;
	}
	return str
}

// 图片转换
const imgConvert = (params : any) : string => {
	const url = new URL(`../${params}.png`, import.meta.url).href;
	const url1 = new URL(`@/assets/icon_2.png`, import.meta.url).href;
	return new URL(`@/assets/${params}.png`, import.meta.url).href;
}

// 权限按钮
const AuthSetUp = (id : any) => {
	const user = JSON.parse(localStorage.getItem("userInfo") || "{}");
	let status = false;
	if (user.is_all_manager == 1) {
		status = true;
	} else {
		if (user.nodes.includes(id.toString())) {
			status = true;
		}
	}
	return status
};

const data = ref([{
	id: 1,
	label: '首页',
	children: [{
		id: 2,
		label: '数据统计',
		isPenultimate: true,
		children: [],
	}],
}, {
	id: 3,
	label: '仓库管理',
	children: [{
		id: 4,
		label: '仓库管理',
		isPenultimate: true,
		children: [{
			id: 5,
			label: '创建仓库',
		}, {
			id: 6,
			label: '查看库区库位',
		}, {
			id: 7,
			label: '设置库区',
		}, {
			id: 150,
			label: '设置巷道',
		}, {
			id: 8,
			label: '设置货架',
		}, {
			id: 9,
			label: '设置货位',
		}, {
			id: 10,
			label: '编辑仓库信息',
		}, {
			id: 11,
			label: '删除仓库',
		}],
	}, {
		id: 14,
		label: '库区管理',
		isPenultimate: true,
		children: [{
			id: 15,
			label: '创建库区',
		}, {
			id: 16,
			label: '编辑库区',
		}, {
			id: 17,
			label: '删除库区',
		}],
	}, {
		id: 155,
		label: '库位管理',
		isPenultimate: true,
		children: [{
			id: 156,
			label: '创建库位',
		}, {
			id: 157,
			label: '编辑库位',
		}, {
			id: 158,
			label: '删除库位',
		}],
	}],
}, {
	id: 19,
	label: '上下游管理',
	children: [{
		id: 20,
		label: '供应商管理',
		isPenultimate: true,
		children: [{
			id: 21,
			label: '创建供应商',
		}, {
			id: 22,
			label: '编辑供应商',
		}, {
			id: 23,
			label: '删除供应商',
		}],
	}],
}, {
	id: 75,
	label: '商品管理',
	children: [{
		id: 76,
		label: '商品类型',
		isPenultimate: true,
		children: [{
			id: 77,
			label: '添加商品类型',
		}, {
			id: 78,
			label: '删除商品类型',
		}, {
			id: 153,
			label: '编辑商品类型',
		}],
	}, {
		id: 83,
		label: '商品管理',
		isPenultimate: true,
		children: [{
			id: 84,
			label: '添加商品',
		}, {
			id: 85,
			label: '删除商品',
		}],
	}, {
		id: 88,
		label: '计量单位',
		isPenultimate: true,
		children: [{
			id: 89,
			label: '添加计量单位',
		}, {
			id: 90,
			label: '编辑计量单位',
		}, {
			id: 91,
			label: '删除计量单位',
		}],
	}, {
		id: 93,
		label: '临期预警',
		isPenultimate: true,
		children: [{
			id: 94,
			label: '查看临期预警',
		}],
	}, {
		id: 24,
		label: '库存预警',
		isPenultimate: true,
		children: [{
			id: 25,
			label: '查看库存预警',
		}],
	}],
}, {
	id: 96,
	label: '仓库作业',
	children: [{
		id: 97,
		label: '入库管理',
		isPenultimate: true,
		children: [{
			id: 98,
			label: '创建入库单',
		}, {
			id: 99,
			label: '查看详情',
		}],
	}, {
		id: 26,
		label: '出库管理',
		isPenultimate: true,
		children: [{
			id: 27,
			label: '创建出库单',
		}, {
			id: 28,
			label: '查看详情',
		}],
	}, {
		id: 29,
		label: '移库管理',
		isPenultimate: true,
		children: [{
			id: 30,
			label: '创建移库单',
		}, {
			id: 31,
			label: '审核移库单',
		}, {
			id: 32,
			label: '查看移库单详情',
		}, {
			id: 33,
			label: '移库操作',
		}],
	}, {
		id: 34,
		label: '调拨管理',
		isPenultimate: true,
		children: [{
			id: 35,
			label: '创建调拨单',
		}, {
			id: 36,
			label: '审核调拨单',
		}, {
			id: 37,
			label: '查看调拨单详情',
		}],
	}, {
		id: 38,
		label: '盘点管理',
		isPenultimate: true,
		children: [{
			id: 39,
			label: '创建盘点单',
		}, {
			id: 40,
			label: '审核盘点单',
		}, {
			id: 41,
			label: '查看盘点单详情',
		}],
	}],
}, {
	id: 102,
	label: '报表管理',
	children: [{
		id: 103,
		label: '报表管理',
		isPenultimate: true,
		children: [{
			id: 104,
			label: '库存明细',
		}, {
			id: 105,
			label: '库存报表',
		}, {
			id: 106,
			label: '库存台账',
		}, {
			id: 107,
			label: '库存周转率',
		}, {
			id: 108,
			label: '入库明细',
		}, {
			id: 42,
			label: '出库明细',
		}],
	}],
}, {
	id: 115,
	label: '系统管理',
	children: [{
		id: 116,
		label: '系统管理',
		isPenultimate: true,
		children: [{
			id: 117,
			label: '角色管理',
		}, {
			id: 118,
			label: '员工管理',
		}, {
			id: 119,
			label: '仓库权限',
		}, {
			id: 120,
			label: '单据字头',
		}, {
			id: 121,
			label: '系统配置',
		}],
	}],
}]);
const SetAuth = (params : any) => {
	const dataArr = JSON.parse(JSON.stringify(data.value));
	if (params.length > 0) {
		params.forEach((item : any) => {
			dataArr.forEach((v : any, i : any) => {
				if (item == v.id) {
					dataArr.splice(i, 1);
				}
				v.children.forEach((vv : any, ii : any) => {
					if (item == vv.id) {
						v.children.splice(ii, 1);
					}
					vv.children.forEach((vvv : any, iii : any) => {
						if (item == vvv.id) {
							vv.children.splice(iii, 1);
						}
					});
				});
			});
		});
	}
	return dataArr
};

export default {
	getCurrentTime,
	imgConvert,
	AuthSetUp,
	SetAuth,
};