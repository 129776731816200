<template>
	<div class="ml-12">
		<el-button class="remove-btn" plain @click="Reset">清空</el-button>
	</div>
</template>

<script lang="ts" setup>
	import { defineEmits, getCurrentInstance } from 'vue';
	
	const { proxy } = getCurrentInstance() as any;
	const emits = defineEmits(["change"]);
	
	const Reset = () => {
		emits("change");
	};
</script>

<style lang="scss" scoped>
	.remove-btn {
		width: 60px;
		height: 32px;
		border-radius: 4px;
		font-size: 14px;
		line-height: 32px;
	}
</style>