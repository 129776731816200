<template>
	<div>
		<el-button class="search-btn" type="primary" @click="Search">搜索</el-button>
	</div>
</template>

<script lang="ts" setup>
	import { defineEmits, getCurrentInstance } from 'vue';
	
	const { proxy } = getCurrentInstance() as any;
	const emits = defineEmits(["change"]);
	
	const Search = () => {
		emits("change");
	};
</script>

<style lang="scss" scoped>
	.search-btn {
		width: 60px;
		height: 32px;
		border-radius: 4px;
		font-size: 14px;
		line-height: 32px;
	}
</style>