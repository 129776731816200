<template>
	<div>
		<el-button class="export-btn" plain type="primary" @click="ExportFile">
			<img class="wh-14" src="@/assets/icon_12.png" alt="">
			<span class="ml-8 fz-14 lh-36">导出</span>
		</el-button>
	</div>
</template>

<script lang="ts" setup>
	import { ref, defineProps, getCurrentInstance, watch } from 'vue';

	const props = defineProps({
		data: {
			type: Object,
			default: function () {
				return {}
			}
		},
		text: {
			type: String,
			default: "导出"
		}
	});

	watch(() => props.data.data, (n : any) => {
		list.value = n;
	});

	const { proxy } = getCurrentInstance() as any;
	const loading = ref(false);
	const list = ref([]);

	const ExportFile = () => {
		if (!props.data.api) {
			proxy.$message.error("api链接不能为空");
			return false;
		}
		console.log(props.data.data)
		if (props.data.data.length == 0 && props.data.export_type == 1) {
			proxy.$message.error(props.data.ErrorTips);
			return false;
		}
		if (loading.value) {
			return false;
		}
		loading.value = true;
		let ids : any = [];
		list.value.forEach((v : any) => {
			ids.push(v.id);
		});
		proxy.$api.tokenRequest({
			url: props.data.api,
			method: "POST",
			isLoading: '加载中...',
			data: {
				type: props.data.type,
				export_type: props.data.export_type,
				ids: ids,
				params: props.data.params || {},
			},
			success: (res : any) => {
				loading.value = false;
				let a : any = document.createElement('a');
				a.href = res.data;
				a.setAttribute('download', "导出模板");
				a.click();
				a = null;
			},
			fail: () => {
				loading.value = false;
			},
		});
	};
</script>

<style lang="scss" scoped>
	.export-btn {
		width: 102px;
		height: 36px;
		border-radius: 4px;
		background: transparent;
		color: #0066FF;
	}
</style>