<template>
	<div class="flex-row-center-start pointer" @click="RefreshList">
		<img class="wh-20" src="@/assets/icon_17.png" alt="">
		<span class="fz-14 co-1F2533 ml-8">刷新</span>
	</div>
</template>

<script lang="ts" setup>
	import { getCurrentInstance, defineEmits } from 'vue';
	
	const { proxy } = getCurrentInstance() as any;
	const emits = defineEmits(["change"]);
	
	const RefreshList = () => {
		emits("change");
	}
</script>

<style lang="scss" scoped>
</style>