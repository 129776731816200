<template>
	<div>
		<el-button class="download-btn" plain type="primary" @click="TemplateDownLoad">
			<img class="wh-14" src="@/assets/icon_11.png" alt="">
			<span class="ml-8 fz-14 lh-36">模板下载</span>
		</el-button>
	</div>
</template>

<script lang="ts" setup>
	import { ref, defineProps, getCurrentInstance } from 'vue';

	const props = defineProps({
		data: {
			type: Object,
			default: function () {
				return {}
			}
		},
	});

	const { proxy } = getCurrentInstance() as any;
	const loading = ref(false);

	const TemplateDownLoad = () => {
		if (!props.data.api) {
			proxy.$message.error("api链接不能为空");
			return false;
		}
		if (loading.value) {
			return false;
		}
		loading.value = true;
		proxy.$api.tokenRequest({
			url: props.data.api,
			isLoading: '加载中...',
			success: (res : any) => {
				loading.value = false;
				let a : any = document.createElement('a');
				a.href = res.data;
				a.setAttribute('download', `${props.data.name}模板`);
				a.click();
				a = null;
			},
			fail: () => {
				loading.value = false;
			},
		});
	};
</script>

<style lang="scss" scoped>
	.download-btn {
		padding-left: 20px;
		padding-right: 20px;
		height: 36px;
		border-radius: 4px;
		background: transparent;
		color: #0066FF;
	}
</style>