<template>
	<el-select remote-show-suffix filterable :clearable="props.clearable" :style="{'width': `${width}`}" :disabled="disabled"
		:size="size" v-model="WarehouseId" :loading="loading" @focus="SelectFocus" :placeholder="placeholder"
		@change="WarehouseChange" @clear="WarehouseClear">
		<el-option v-for="v in WarehouseList" :key="v[ValueKey]" :label="v[LabelKey]" :value="v[ValueKey]" />
	</el-select>
</template>

<script lang="ts" setup>
	import { ref, getCurrentInstance, defineEmits, defineProps, withDefaults, onMounted, watch } from 'vue';

	interface main {
		id ?: string | number,
		size ?: string,
		ValueKey ?: string,
		LabelKey ?: string,
		width ?: string | number,
		disabled ?: boolean,
		IsAuto ?: boolean,
		clearable ?: boolean,
	};

	const props = withDefaults(defineProps<main>(), {
		id: "",
		size: "default",
		ValueKey: "value",
		LabelKey: "label",
		width: "100%",
		disabled: false,
		IsAuto: false,
		clearable: false,
	});

	const { proxy } = getCurrentInstance() as any;
	const emits = defineEmits(["update:id", "change", "clear"]);
	const WarehouseId = ref<any>("");
	const WarehouseList = ref<any>([]);
	const loading = ref(false);
	const placeholder = ref("请选择仓库");

	watch(() => props.id, (n : any) => {
		WarehouseId.value = n;
	});

	onMounted(() => {
		WarehouseId.value = props.id;
		if (props.IsAuto) {
			if (proxy.$store.state.WarehouseList.length > 0) {
				WarehouseList.value = proxy.$store.state.WarehouseList;
				if (WarehouseList.value.length == 1) {
					WarehouseId.value = WarehouseList.value[0].value;
					emits("update:id", WarehouseList.value[0].value);
					emits("change", WarehouseList.value[0].value);
				}
			} else {
				proxy.$api.tokenRequest({
					url: '/admin/warehouse/listByType',
					data: {
						type: 1,
					},
					success: (res : any) => {
						loading.value = false;
						WarehouseList.value = res.data;
						if (WarehouseList.value.length == 1) {
							WarehouseId.value = WarehouseList.value[0].value;
							emits("update:id", WarehouseList.value[0].value);
							emits("change", WarehouseList.value[0].value);
						}
						proxy.$store.commit("GetAllList", {
							type: 1,
							data: res.data,
						});
					},
				});
			}
		}
	});

	const WarehouseChange = (data : any) => {
		emits("update:id", data);
		emits("change", data);
	};
	const WarehouseClear = () => {
		emits("clear");
	};
	const SelectFocus = () => {
		if (WarehouseList.value.length == 0 && !props.IsAuto) {
			if (proxy.$store.state.WarehouseList.length > 0) {
				WarehouseList.value = proxy.$store.state.WarehouseList;
				if (WarehouseList.value.length == 1) {
					WarehouseId.value = WarehouseList.value[0].value;
					emits("update:id", WarehouseList.value[0].value);
					emits("change", WarehouseList.value[0].value);
				}
			} else {
				proxy.$api.tokenRequest({
					url: '/admin/warehouse/listByType',
					data: {
						type: 1,
					},
					success: (res : any) => {
						loading.value = false;
						WarehouseList.value = res.data;
						if (WarehouseList.value.length == 1) {
							WarehouseId.value = WarehouseList.value[0].value;
							emits("update:id", WarehouseList.value[0].value);
							emits("change", WarehouseList.value[0].value);
						}
						proxy.$store.commit("GetAllList", {
							type: 1,
							data: res.data,
						});
					},
				});
			}
		}
	};
</script>

<style>
</style>