<template>
	<div>
		<el-button class="print-btn" type="primary" v-print="print" v-if="PrintArr.length > 0">
			<span class="fz-14 lh-36">{{text}}</span>
		</el-button>
		<el-button class="print-btn" type="primary" @click="StartPrint" v-else>
			<span class="fz-14 lh-36">{{text}}</span>
		</el-button>
		<div class="fixed print-box">
			<div id="printBox">
				<div style="page-break-after:always" v-for="(v, i) in PrintArr" :key="i">
					<div class="flex-row-start-sb">
						<div class="flex-1">
							<div class="font-blod title">{{name}}</div>
							<div class="flex-row info-box">
								<div>
									<div class="flex-row-center-start info-box-item">
										<div class="co-949CA9">入库单号：</div>
										<div>{{v.order_sn}}</div>
									</div>
									<div class="flex-row-center-start info-box-item">
										<div class="co-949CA9">入库类型：</div>
										<div>{{v.type_text}}</div>
									</div>
									<div class="flex-row-center-start info-box-item">
										<div class="co-949CA9">存储仓库：</div>
										<div>{{v.warehouse_text}}</div>
									</div>
								</div>
								<div class="ml-60">
									<div class="flex-row-center-start info-box-item">
										<div class="co-949CA9">订单来源：</div>
										<div>{{v.add_type_text}}</div>
									</div>
									<div class="flex-row-center-start info-box-item">
										<div class="co-949CA9">创建人：</div>
										<div>{{v.admin_name}}</div>
									</div>
									<div class="flex-row-center-start info-box-item">
										<div class="co-949CA9">创建时间：</div>
										<div>{{v.create_time}}</div>
									</div>
								</div>
							</div>
						</div>
						<div class="flex-column flex-align-end">
							<div class="flex-column-center-center">
								<img class="code_url" :src="v.barcode_url" alt="">
								<div class="order_sn co-1F2D48">{{v.order_sn}}</div>
							</div>
							<div class="status_text font-blod co-FF8700">{{v.status_text}}</div>
						</div>
					</div>
					<div class="flex-row-center-sb td-title font-blod" style="margin-top: 1mm;">
						<div class="flex-row-center-center index">序号</div>
						<div class="flex-row-center-center flex-1">SKU</div>
						<div class="flex-row-center-center flex-1">名称</div>
						<div class="flex-row-center-center date">生产日期</div>
						<div class="flex-row-center-center unit">计量单位</div>
						<div class="flex-row-center-center number">预报数量</div>
						<div class="flex-row-center-center flex-1">备注</div>
					</div>
					<div class="flex-row-center-sb td-title ali-stretch" style="border-bottom: 0.01mm solid #F7F7F7;" v-for="(v, i) in list" :key="i">
						<div class="flex-row-center-center index">{{i + 1}}</div>
						<div class="flex-row-center-center flex-1">
							<span class="two-row good_sn">{{v.good_sn}}</span>
						</div>
						<div class="flex-row-center-center flex-1">
							<span class="three-row">{{v.good_name}}</span>
						</div>
						<div class="flex-row-center-center date">{{v.good_quality_day}}</div>
						<div class="flex-row-center-center unit">{{v.good_unit}}</div>
						<div class="flex-row-center-center number">{{v.number}}</div>
						<div class="flex-row-center-center flex-1">{{v.remark}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
	import { defineProps, ref, watch, getCurrentInstance } from 'vue';

	const props = defineProps({
		text: {
			type: String,
			default: "",
		},
		SelectArr: {
			type: Array,
			default: function () {
				return []
			},
		},
		list: {
			type: Array,
			default: function () {
				return []
			},
		},
		name: {
			type: String,
			default: "",
		}
	});

	const { proxy } = getCurrentInstance() as any;
	const PrintArr = ref<any>([]);
	const print = ref<any>({
		id: 'printBox',//这里的id就是上面我们的打印区域id，实现指哪打哪
		popTitle: '', // 打印配置页上方的标题
		extraCss: "", // 打印可引入外部的一个 css 文件
		extraHead: ' ', // 最上方的头部文字，附加在head标签上的额外标签，使用逗号分割
		preview: false, // 是否启动预览模式，默认是false
		previewTitle: ' ', // 打印预览的标题
		previewPrintBtnLabel: ' ', // 打印预览的标题下方的按钮文本，点击可进入打印
		zIndex: 20002, // 预览窗口的z-index，默认是20002，最好比默认值更高
	});

	watch(() => props.SelectArr, (n : any) => {
		PrintArr.value = n;
	}, {
		immediate: true,
	});

	const StartPrint = () => {
		proxy.$message.error("请选择库位");
	};
</script>

<style lang="scss" scoped>
	.print-btn {
		height: 36px;
		border-radius: 4px;
	}

	.print-box {
		top: -800px;
		left: -1000000px;
	}

	@media print {
		@page {
			size: auto;
			margin: 4mm;
		}

		body,
		html {
			height: auto !important;
		}

		#printBox {
			height: 100%;
		}

		#printBox .title {
			font-size: 4mm;
			padding-bottom: 1mm;
		}

		#printBox .info-box {
			font-size: 3mm;
		}

		#printBox .info-box .info-box-item {
			margin-top: 1mm;
		}

		#printBox .code_url {
			width: 50mm;
			height: 20mm;
		}

		#printBox .order_sn {
			font-size: 3mm;
		}

		#printBox .status_text {
			font-size: 5mm;
		}

		#printBox .td-title {
			font-size: 3mm;
			padding-top: 2mm;
			padding-bottom: 2mm;
		}
		
		#printBox .index {
			width: 10mm;
		}
		
		#printBox .unit {
			width: 15mm;
		}
		
		#printBox .number {
			width: 20mm;
		}
		
		#printBox .date {
			width: 30mm;
		}
		
		#printBox .good_sn {
			padding-left: 1mm;
			padding-right: 1mm;
		}
	}
</style>