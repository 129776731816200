<template>
	<div>
		<el-dialog v-model="pop" title="" width="320px" :show-close="false" destroy-on-close :align-center="true">
			<template #header>
				<div class="flex-row-center-sb pl-20 ptb-20">
					<div class="fz-18 co-1F2D48 font-blod">{{props.title}}</div>
					<div class="pr-20 pointer" @click="close">
						<img class="wh-12" src="@/assets/icon_15.png" alt="">
					</div>
				</div>
			</template>
			<div class="fz-15 co-1F2D48 pb-30 plr-20">{{tips}}</div>
			<div class="flex-row-center-end pr-20 pb-20">
				<el-button class="cancel-btn" @click="close">取消</el-button>
				<el-button class="sure-btn" type="primary" @click="submit">{{props.sureText}}</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script lang="ts" setup>
	import { ref, defineExpose, defineEmits, withDefaults, defineProps } from 'vue';

	interface main {
		sureText ?: string,
		title ?: string,
	}

	const props = withDefaults(defineProps<main>(), {
		sureText: "确认",
		title: "提示",
	});
	const emits = defineEmits(["change"]);
	const pop = ref(false);
	const tips = ref("");

	const submit = () => {
		close();
		emits("change");
	};
	const open = (params : any) => {
		pop.value = true;
		tips.value = params;
	};
	const close = () => {
		pop.value = false;
	};

	defineExpose({
		open,
		close,
	});
</script>

<style lang="scss" scoped>
	@import "@/styles/publicscss/publicscss.scss";

	.cancel-btn {
		width: 80px;
		height: 36px;
		border-radius: 4px;
		font-size: 14px;
		color: #1F2D48;
	}

	.sure-btn {
		width: 80px;
		height: 36px;
		border-radius: 4px;
		font-size: 14px;
		color: #FFFFFF;
	}
</style>