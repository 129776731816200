<template>
	<div class="w-100">
		<el-select remote-show-suffix filterable clearable :style="{'width': `${width}`}" :disabled="disabled" :size="size"
			v-model="UserId" :loading="loading" @focus="SelectFocus" :placeholder="placeholder" @change="PdaUserChange"
			@clear="PdaUserClear">
			<el-option v-for="v in PdaUserList" :key="v[ValueKey]" :label="v[LabelKey]" :value="v[ValueKey]" />
		</el-select>
	</div>
</template>

<script lang="ts" setup>
	import { ref, getCurrentInstance, defineEmits, defineProps, withDefaults, onMounted, watch } from 'vue';

	interface main {
		id ?: string | number,
		size ?: string,
		placeholder ?: string,
		ValueKey ?: string,
		LabelKey ?: string,
		width ?: string | number,
		disabled ?: boolean,
		IsAuto ?: boolean,
		WarehouseId ?: string | number,
		AreaId ?: string | number,
	};

	const props = withDefaults(defineProps<main>(), {
		id: "",
		size: "default",
		placeholder: "请选择关联人员",
		ValueKey: "id",
		LabelKey: "nickname",
		width: "100%",
		disabled: false,
		IsAuto: false,
		WarehouseId: "",
		AreaId: "",
	});

	const { proxy } = getCurrentInstance() as any;
	const emits = defineEmits(["update:id", "change", "clear"]);
	const UserId = ref<any>("");
	const PdaUserList = ref<any>([]);
	const loading = ref(false);

	watch(() => props.id, (n : any) => {
		UserId.value = n;
	});

	onMounted(() => {
		UserId.value = props.id;
		if (props.IsAuto) {
			if (proxy.$store.state.IsRefreshPdaUser) {
				GetData();
			} else {
				if (proxy.$store.state.PdaUserList.length > 0) {
					PdaUserList.value = proxy.$store.state.PdaUserList;
				} else {
					GetData();
				}
			}
		}
	});

	const PdaUserChange = (data : any) => {
		let item : any = PdaUserList.value.find((v : any) => v.id == data);
		emits("update:id", data);
		emits("change", item);
	};
	const PdaUserClear = () => {
		emits("clear");
	};
	const SelectFocus = () => {
		if (proxy.$store.state.IsRefreshPdaUser) {
			GetData();
		} else {
			if (proxy.$store.state.PdaUserList.length > 0) {
				PdaUserList.value = proxy.$store.state.PdaUserList;
			} else {
				GetData();
			}
		}
	};
	const GetData = () => {
		loading.value = true;
		proxy.$api.tokenRequest({
			url: '/admin/admin/usersFromType',
			data: {
				type: 2,
				is_page: 0,
			},
			success: (res : any) => {
				loading.value = false;
				PdaUserList.value = res.data;
				proxy.$store.commit("GetAllList", {
					type: 24,
					data: res.data,
				});
			},
			fail: () => {
				loading.value = false;
			},
		});
	};
</script>

<style>
</style>