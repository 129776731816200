<template>
	<div class="flex-row-center-center pt-30 pagination">
		<el-pagination background layout="prev, pager, next, sizes, jumper" :page-sizes="config.PagesSize"
			:total="total" :current-page="pages" :page-size="size" @size-change="handleSizeChange"
			@current-change="handleCurrentChange" />
	</div>
</template>

<script lang="ts" setup>
	import { ref, defineEmits, defineProps, withDefaults, watch } from 'vue';
	import config from '@/api/config';
	
	interface main {
		page ?: string | number,
		total ?: string | number,
	}
	
	const props = withDefaults(defineProps<main>(), {
		page: 1,
		total: 0,
	})
	
	const emits = defineEmits(["update:page", "change"]);
	const pages = ref(1);
	const size = ref(config.PagesSize[0]);
	
	watch(() => props.page, (n : any) => {
		pages.value = n;
	});
	
	// 显示条数改变
	const handleSizeChange = (params : any) => {
		size.value = params;
		emits("change", {
			type: 1,
			page: 1,
			size: params,
		});
	};
	// 分页改变
	const handleCurrentChange = (params : any) => {
		pages.value = params;
		emits("update:page", params);
		emits("change", {
			type: 2,
			page: params,
			size: size.value,
		})
	};
</script>

<style>
</style>