import store from '@/store/index';

let TextList : any = [{
	id: 1,
	cn: "请输入账号",
	tc: "請輸入帳號",
	en: "Please Enter Account",
}, {
	id: 2,
	cn: "请输入密码",
	tc: "請輸入密碼",
	en: "Please Enter Password",
}, {
	id: 3,
	cn: "记住密码",
	tc: "記住密碼",
	en: "Remember Password",
}, {
	id: 4,
	cn: "登录中...",
	tc: "登入中...",
	en: "Log In...",
}, {
	id: 5,
	cn: "登录",
	tc: "登入",
	en: "Log In",
}, {
	id: 6,
	cn: "WMS智能仓储管理系统",
	tc: "WMS智慧倉儲管理系統",
	en: "",
}, {
	id: 7,
	cn: "今日入库物料数",
	tc: "今日入庫物料數",
	en: "In Storage Today",
}, {
	id: 8,
	cn: "今日出库物料数",
	tc: "今日出庫物料數",
	en: "Delivery today",
}, {
	id: 9,
	cn: "今日吞吐量 (t)",
	tc: "今日輸送量 (t)",
	en: "",
}, {
	id: 10,
	cn: "今日入账 (元)",
	tc: "今日入帳 (元)",
	en: "",
}, {
	id: 11,
	cn: "仓库使用率",
	tc: "倉庫使用率",
	en: "",
}, {
	id: 12,
	cn: "库位空闲",
	tc: "庫位空閒",
	en: "",
}, {
	id: 13,
	cn: "库位占用",
	tc: "庫位佔用",
	en: "",
}, {
	id: 14,
	cn: "供应商数量",
	tc: "供應商數量",
	en: "",
}, {
	id: 15,
	cn: "客户数量",
	tc: "客戶數量",
	en: "",
}, {
	id: 16,
	cn: "过期",
	tc: "過期",
	en: "",
}, {
	id: 17,
	cn: "所属企业",
	tc: "所屬企業",
	en: "",
}, {
	id: 18,
	cn: "员工数量",
	tc: "員工數量",
	en: "",
}, {
	id: 19,
	cn: "仓库数据统计",
	tc: "倉庫數據統計",
	en: "",
}, {
	id: 20,
	cn: "入库数据",
	tc: "入庫數據",
	en: "",
}, {
	id: 21,
	cn: "出库数据",
	tc: "出庫數據",
	en: "",
}, {
	id: 22,
	cn: "仓库使用率",
	tc: "倉庫使用率",
	en: "",
}, {
	id: 23,
	cn: "订单平均耗时",
	tc: "訂單平均耗時",
	en: "",
}, {
	id: 24,
	cn: "今日",
	tc: "今日",
	en: "Today",
}, {
	id: 25,
	cn: "近7日",
	tc: "近7日",
	en: "Last 7 days",
}, {
	id: 26,
	cn: "近1月",
	tc: "近1月",
	en: "Last 1 Month",
}, {
	id: 27,
	cn: "预警信息",
	tc: "預警信息",
	en: "Warning information",
}, {
	id: 28,
	cn: "托盘编号",
	tc: "託盤編號",
	en: "",
}, {
	id: 29,
	cn: "托盘名称",
	tc: "託盤名稱",
	en: "",
}, {
	id: 30,
	cn: "托盘形状",
	tc: "託盤形狀",
	en: "",
}, {
	id: 31,
	cn: "托盘材质",
	tc: "託盤材質",
	en: "",
}, {
	id: 32,
	cn: "快速上手",
	tc: "快速上手",
	en: "",
}, {
	id: 33,
	cn: "STEP1 基础数据管理",
	tc: "STEP1 基礎數據管理",
	en: "",
}, {
	id: 34,
	cn: "STEP2 角色与权限分配",
	tc: "STEP2 角色與權限分配",
	en: "",
}, {
	id: 35,
	cn: "STEP3 仓库作业及硬件配置",
	tc: "STEP3 倉庫作業及硬件配置",
	en: "",
}, {
	id: 36,
	cn: "STEP4 报表管理",
	tc: "STEP4 報表管理",
	en: "",
}, {
	id: 37,
	cn: "搜索",
	tc: "搜索",
	en: "Search",
}, {
	id: 38,
	cn: "清空",
	tc: "清空",
	en: "Empty",
}, {
	id: 39,
	cn: "上传文件以导入托盘类型",
	tc: "上傳文件以導入託盤類型",
	en: "",
}, {
	id: 40,
	cn: "托盘类型导入模板",
	tc: "託盤類型導入模板",
	en: "",
}, {
	id: 41,
	cn: "添加",
	tc: "添加",
	en: "Add",
}, {
	id: 42,
	cn: "请选择托盘类型",
	tc: "請選擇託盤類型",
	en: "",
}, {
	id: 43,
	cn: "请输入名称",
	tc: "請輸入名稱",
	en: "",
}, {
	id: 44,
	cn: "请选择形状",
	tc: "請選擇形狀",
	en: "",
}, {
	id: 45,
	cn: "请选择材质",
	tc: "請選擇材質",
	en: "",
}, {
	id: 46,
	cn: "下载导入模板",
	tc: "下載導入模板",
	en: "",
}, {
	id: 47,
	cn: "确定",
	tc: "確定",
	en: "OK",
}, {
	id: 48,
	cn: "文件上传中",
	tc: "文件上傳中",
	en: "",
}, {
	id: 49,
	cn: "点击或将文件拖拽到这里上传",
	tc: "點擊或將文件拖拽到這裡上傳",
	en: "",
}, {
	id: 50,
	cn: "删除",
	tc: "删除",
	en: "Delete",
}, {
	id: 51,
	cn: "编号",
	tc: "編號",
	en: "",
}, {
	id: 52,
	cn: "名称",
	tc: "名稱",
	en: "Name",
}, {
	id: 53,
	cn: "尺寸",
	tc: "尺寸",
	en: "Size",
}, {
	id: 54,
	cn: "形状",
	tc: "形狀",
	en: "",
}, {
	id: 55,
	cn: "材质",
	tc: "材质",
	en: "",
}, {
	id: 56,
	cn: "状态",
	tc: "狀態",
	en: "Status",
}, {
	id: 57,
	cn: "操作",
	tc: "操作",
	en: "Operation",
}, {
	id: 58,
	cn: "编辑",
	tc: "編輯",
	en: "Edit",
}, {
	id: 59,
	cn: "导出",
	tc: "導出",
	en: "",
}, {
	id: 60,
	cn: "导入",
	tc: "導入",
	en: "",
}, {
	id: 61,
	cn: "入库类型配置",
	tc: "入庫類型配置",
	en: "",
}, {
	id: 62,
	cn: "出库类型配置",
	tc: "出庫類型配置",
	en: "",
}, {
	id: 63,
	cn: "库位类型配置",
	tc: "庫位類型配置",
	en: "",
}, {
	id: 64,
	cn: "库区类型配置",
	tc: "庫區類型配置",
	en: "",
}, {
	id: 65,
	cn: "请输入名称",
	tc: "請輸入名稱",
	en: "Please Enter Name...",
}, {
	id: 66,
	cn: "编辑入库类型",
	tc: "編輯入庫類型",
	en: "",
}, {
	id: 67,
	cn: "添加入库类型",
	tc: "添加入庫類型",
	en: "",
}, {
	id: 68,
	cn: "编辑出库类型",
	tc: "編輯出庫類型",
	en: "",
}, {
	id: 69,
	cn: "添加出库类型",
	tc: "添加出庫類型",
	en: "",
}, {
	id: 70,
	cn: "编辑库区类型",
	tc: "編輯庫區類型",
	en: "",
}, {
	id: 71,
	cn: "添加库区类型",
	tc: "添加庫區類型",
	en: "",
}, {
	id: 72,
	cn: "编辑库位类型",
	tc: "編輯庫位類型",
	en: "",
}, {
	id: 73,
	cn: "添加库位类型",
	tc: "添加庫位類型",
	en: "",
}, {
	id: 74,
	cn: "取消",
	tc: "取消",
	en: "cancel",
}, {
	id: 75,
	cn: "保存中",
	tc: "保存中",
	en: "Save",
}, {
	id: 76,
	cn: "保存",
	tc: "保存",
	en: "Save",
}, {
	id: 77,
	cn: "类型编码",
	tc: "類型編碼",
	en: "",
}, {
	id: 78,
	cn: "编辑托盘类型",
	tc: "編輯託盤類型",
	en: "",
}, {
	id: 79,
	cn: "添加托盘类型",
	tc: "添加託盤類型",
	en: "",
}, {
	id: 80,
	cn: "添加托盘类型",
	tc: "添加託盤類型",
	en: "",
}, {
	id: 81,
	cn: "托盘名称",
	tc: "託盤名稱",
	en: "",
}, {
	id: 82,
	cn: "托盘形状",
	tc: "託盤形狀",
	en: "",
}, {
	id: 83,
	cn: "托盘材质",
	tc: "託盤材質",
	en: "",
}, {
	id: 84,
	cn: "请选择托盘类型",
	tc: "請選擇託盤類型",
	en: "",
}, {
	id: 85,
	cn: "请选择托盘材质",
	tc: "請選擇託盤材質",
	en: "",
}, {
	id: 86,
	cn: "请选择状态",
	tc: "請選擇狀態",
	en: "",
}, {
	id: 87,
	cn: "请输入长",
	tc: "請輸入長",
	en: "",
}, {
	id: 88,
	cn: "请输入宽",
	tc: "請輸入寬",
	en: "",
}, {
	id: 89,
	cn: "请输入高",
	tc: "請輸入高",
	en: "",
}, {
	id: 90,
	cn: "仓库编码",
	tc: "倉庫編碼",
	en: "",
}, {
	id: 91,
	cn: "仓库名称",
	tc: "倉庫名稱",
	en: "",
}, {
	id: 92,
	cn: "仓库管理",
	tc: "倉庫管理",
	en: "",
}, {
	id: 93,
	cn: "请输入...",
	tc: "請輸入...",
	en: "Please Enter...",
}, {
	id: 94,
	cn: "备注",
	tc: "備註",
	en: "Remarks",
}, {
	id: 95,
	cn: "库区设置",
	tc: "庫區設置",
	en: "",
}, {
	id: 96,
	cn: "巷道设置",
	tc: "巷道設置",
	en: "",
}, {
	id: 97,
	cn: "货架设置",
	tc: "貨架設置",
	en: "",
}, {
	id: 98,
	cn: "库位设置",
	tc: "庫位設置",
	en: "",
}, {
	id: 99,
	cn: "查看库区库位",
	tc: "查看庫區庫位",
	en: "",
}, {
	id: 100,
	cn: "托盘设置",
	tc: "託盤设置",
	en: "",
}, {
	id: 101,
	cn: "模板下载",
	tc: "模板下載",
	en: "Download Excel template",
}, {
	id: 102,
	cn: "库区编码",
	tc: "庫區編碼",
	en: "",
}, {
	id: 103,
	cn: "库区名称",
	tc: "庫區名稱",
	en: "",
}, {
	id: 104,
	cn: "刷新",
	tc: "刷新",
	en: "Refresh",
}, {
	id: 106,
	cn: "所属仓库",
	tc: "所屬倉庫",
	en: "",
}, {
	id: 107,
	cn: "请选择仓库",
	tc: "請選擇倉庫",
	en: "",
}, {
	id: 108,
	cn: "系统自动生成",
	tc: "系統自動生成",
	en: "Automatically generated by th system",
}, {
	id: 109,
	cn: "请输入库区名称",
	tc: "請輸入庫區名稱",
	en: "",
}, {
	id: 110,
	cn: "库区类型",
	tc: "庫區類型",
	en: "",
}, {
	id: 111,
	cn: "请选择库区类型",
	tc: "請選擇庫區類型",
	en: "",
}, {
	id: 112,
	cn: "请输入仓容定额",
	tc: "請輸入倉容定額",
	en: "",
}, {
	id: 113,
	cn: "仓容定额",
	tc: "倉容定額",
	en: "",
}, {
	id: 114,
	cn: "库区",
	tc: "庫區",
	en: "",
}, {
	id: 115,
	cn: "所属库区",
	tc: "所屬庫區",
	en: "",
}, {
	id: 116,
	cn: "该数据已关联其他数据，删除该数据后将删除下面关联的所有数据，是否确认并继续？",
	tc: "該數據已關聯其他數據，删除該數加载中據後將删除下麵關聯的所有數據，是否確認並繼續？",
	en: "",
}, {
	id: 117,
	cn: "请选择巷道",
	tc: "請選擇巷道",
	en: "",
}, {
	id: 118,
	cn: "加载中...",
	tc: "加載中...",
	en: "Loading...",
}, {
	id: 119,
	cn: "请选择库区",
	tc: "請選擇庫區",
	en: "",
}, {
	id: 120,
	cn: "行数",
	tc: "行數",
	en: "",
}, {
	id: 121,
	cn: "列数",
	tc: "列數",
	en: "",
}, {
	id: 122,
	cn: "创建库位",
	tc: "創建庫位",
	en: "",
}, {
	id: 123,
	cn: "请选择货架",
	tc: "請選擇貨架",
	en: "",
}, {
	id: 124,
	cn: "巷道编码",
	tc: "巷道編碼",
	en: "",
}, {
	id: 125,
	cn: "巷道名称",
	tc: "巷道名稱",
	en: "",
}, {
	id: 126,
	cn: "请输入巷道名称",
	tc: "請輸入巷道名稱",
	en: "",
}, {
	id: 127,
	cn: "请选择所属仓库",
	tc: "請選擇所屬倉庫",
	en: "",
}, {
	id: 128,
	cn: "货架编码",
	tc: "貨架編碼",
	en: "",
}, {
	id: 129,
	cn: "请选择所属库区",
	tc: "請選擇所屬庫區",
	en: "",
}, {
	id: 130,
	cn: "所属巷道",
	tc: "所属巷道",
	en: "",
}, {
	id: 131,
	cn: "请选择所属巷道",
	tc: "請選擇所屬巷道",
	en: "",
}, {
	id: 132,
	cn: "货架名称",
	tc: "貨架名稱",
	en: "",
}, {
	id: 133,
	cn: "请输入货架名称",
	tc: "請輸入貨架名稱",
	en: "",
}, {
	id: 134,
	cn: "请输入行数",
	tc: "請輸入行數",
	en: "",
}, {
	id: 135,
	cn: "请输入列数",
	tc: "請輸入列數",
	en: "",
}, {
	id: 136,
	cn: "仓库库区库位列表",
	tc: "倉庫庫區庫位列錶",
	en: "",
}, {
	id: 137,
	cn: "货架",
	tc: "貨架",
	en: "",
}, {
	id: 138,
	cn: "所属库区编码",
	tc: "所屬庫區編碼",
	en: "",
}, {
	id: 139,
	cn: "所属货架编码",
	tc: "所屬貨架編碼",
	en: "",
}, {
	id: 140,
	cn: "库位编码",
	tc: "庫位編碼",
	en: "",
}, {
	id: 141,
	cn: "当前库位",
	tc: "當前庫位",
	en: "",
}, {
	id: 142,
	cn: "所属货架",
	tc: "所屬貨架",
	en: "",
}, {
	id: 143,
	cn: "请选择所属货架",
	tc: "請選擇所屬貨架",
	en: "",
}, {
	id: 144,
	cn: "库位类型",
	tc: "庫位類型",
	en: "",
}, {
	id: 145,
	cn: "请选择库位类型",
	tc: "請選擇庫位類型",
	en: "",
}, {
	id: 150,
	cn: "最大重量",
	tc: "最大重量",
	en: "",
}, {
	id: 151,
	cn: "请输入最大重量",
	tc: "請輸入最大重量",
	en: "",
}, {
	id: 152,
	cn: "库区管理",
	tc: "庫區管理",
	en: "",
}, {
	id: 153,
	cn: "库区编码",
	tc: "庫區編碼",
	en: "",
}, {
	id: 154,
	cn: "请输入库区编码",
	tc: "請輸入庫區編碼",
	en: "",
}, {
	id: 155,
	cn: "所属仓库编码",
	tc: "所屬倉庫編碼",
	en: "",
}, {
	id: 156,
	cn: "所属仓库名称",
	tc: "所屬倉庫名稱",
	en: "",
}, {
	id: 157,
	cn: "仓容定额 m³",
	tc: "倉容定額 m³",
	en: "",
}, {
	id: 158,
	cn: "库位管理",
	tc: "庫位管理",
	en: "",
}, {
	id: 159,
	cn: "库区编码",
	tc: "庫區編碼",
	en: "",
}, {
	id: 160,
	cn: "库位编码",
	tc: "庫位編碼",
	en: "",
}, {
	id: 161,
	cn: "所属库区名称",
	tc: "所屬庫區名稱",
	en: "",
}, {
	id: 162,
	cn: "所属货架编号",
	tc: "所屬貨架編號",
	en: "",
}, {
	id: 163,
	cn: "请选择库位",
	tc: "請選擇庫位",
	en: "",
}, {
	id: 164,
	cn: "客户名称",
	tc: "客戶名稱",
	en: "",
}, {
	id: 165,
	cn: "客户管理",
	tc: "客戶管理",
	en: "",
}, {
	id: 166,
	cn: "手机号",
	tc: "手機號",
	en: "",
}, {
	id: 167,
	cn: "客户编号",
	tc: "客戶編號",
	en: "",
}, {
	id: 168,
	cn: "客户名称",
	tc: "客戶名稱",
	en: "",
}, {
	id: 169,
	cn: "所属公司",
	tc: "所屬公司",
	en: "",
}, {
	id: 170,
	cn: "固定电话",
	tc: "固定電話",
	en: "",
}, {
	id: 171,
	cn: "邮箱",
	tc: "郵箱",
	en: "",
}, {
	id: 172,
	cn: "请选择客户",
	tc: "請選擇客戶",
	en: "",
}, {
	id: 173,
	cn: "请输入邮箱",
	tc: "請輸入郵箱",
	en: "",
}, {
	id: 174,
	cn: "地区",
	tc: "地區",
	en: "",
}, {
	id: 175,
	cn: "请选择地区",
	tc: "請選擇地區",
	en: "",
}, {
	id: 176,
	cn: "请输入所属公司",
	tc: "請輸入所屬公司",
	en: "",
}, {
	id: 177,
	cn: "详细地址",
	tc: "詳細地址",
	en: "",
}, {
	id: 178,
	cn: "请输入详细地址",
	tc: "請輸入詳細地址",
	en: "",
}, {
	id: 179,
	cn: "请输入手机号",
	tc: "請輸入手機號",
	en: "",
}, {
	id: 180,
	cn: "请输入固定电话",
	tc: "請輸入固定電話",
	en: "",
}, {
	id: 181,
	cn: "供应商管理",
	tc: "供應商管理",
	en: "Supplier Management",
}, {
	id: 182,
	cn: "手机号长度不能小于11位",
	tc: "手機號長度不能小於11位",
	en: "",
}, {
	id: 183,
	cn: "手机号长度不能大于11位",
	tc: "手機號長度不能大於11位",
	en: "",
}, {
	id: 184,
	cn: "请选择供应商",
	tc: "請選擇供應商",
	en: "",
}, {
	id: 185,
	cn: "物料分类",
	tc: "物料分類",
	en: "",
}, {
	id: 186,
	cn: "主分类列表",
	tc: "主分類列表",
	en: "",
}, {
	id: 187,
	cn: "添加主分类",
	tc: "添加主分類",
	en: "",
}, {
	id: 188,
	cn: "添加二级分类",
	tc: "添加二級分類",
	en: "",
}, {
	id: 189,
	cn: "修改主分类",
	tc: "修改主分類",
	en: "",
}, {
	id: 190,
	cn: "请输入主分类名称",
	tc: "請輸入主分類名稱",
	en: "",
}, {
	id: 191,
	cn: "请输入二级分类",
	tc: "請輸入二級分類",
	en: "",
}, {
	id: 192,
	cn: "请选择分类",
	tc: "請選擇分類",
	en: "",
}, {
	id: 193,
	cn: "请输入分类名称",
	tc: "請輸入分類名稱",
	en: "",
}, {
	id: 194,
	cn: "分类名称",
	tc: "分類名稱",
	en: "",
}, {
	id: 195,
	cn: "添加三级分类",
	tc: "添加三級分類",
	en: "",
}, {
	id: 196,
	cn: "请输入三级分类",
	tc: "請輸入三級分類",
	en: "",
}, {
	id: 197,
	cn: "修改二级分类",
	tc: "修改二級分類",
	en: "",
}, {
	id: 198,
	cn: "修改二级分类",
	tc: "修改二級分類",
	en: "",
}, {
	id: 199,
	cn: "三级分类",
	tc: "三級分類",
	en: "",
}, {
	id: 200,
	cn: "修改三级分类",
	tc: "修改三級分類",
	en: "",
}, {
	id: 201,
	cn: "物料管理",
	tc: "物料管理",
	en: "",
}, {
	id: 202,
	cn: "物料SKU",
	tc: "物料SKU",
	en: "",
}, {
	id: 203,
	cn: "请选择...",
	tc: "請選擇...",
	en: "",
}, {
	id: 204,
	cn: "物料规格",
	tc: "物料規格",
	en: "",
}, {
	id: 205,
	cn: "计量单位",
	tc: "計量單位",
	en: "",
}, {
	id: 206,
	cn: "打印物料SKU",
	tc: "打印物料SKU",
	en: "",
}, {
	id: 207,
	cn: "物料价格 元",
	tc: "物料價格 元",
	en: "",
}, {
	id: 208,
	cn: "体积 cm³",
	tc: "體積 cm³",
	en: "",
}, {
	id: 209,
	cn: "重量 g",
	tc: "重量 g",
	en: "",
}, {
	id: 210,
	cn: "重量",
	tc: "重量",
	en: "",
}, {
	id: 211,
	cn: "品牌",
	tc: "品牌",
	en: "",
}, {
	id: 212,
	cn: "请输入物料品牌",
	tc: "請輸入物料品牌",
	en: "",
}, {
	id: 213,
	cn: "请选择物料",
	tc: "請選擇物料",
	en: "",
}, {
	id: 214,
	cn: "单价(RMB)",
	tc: "單價(RMB)",
	en: "",
}, {
	id: 215,
	cn: "请输入价格",
	tc: "請輸入價格",
	en: "",
}, {
	id: 216,
	cn: "体积",
	tc: "體積",
	en: "",
}, {
	id: 217,
	cn: "请输入重量",
	tc: "請輸入重量",
	en: "",
}, {
	id: 218,
	cn: "请输入规格",
	tc: "請輸入規格",
	en: "",
}, {
	id: 219,
	cn: "启用保质期",
	tc: "啟用保質期",
	en: "",
}, {
	id: 220,
	cn: "请选择是否启用",
	tc: "請選擇是否啟用",
	en: "",
}, {
	id: 221,
	cn: "保质期",
	tc: "保質期",
	en: "",
}, {
	id: 222,
	cn: "请输入保质期时长",
	tc: "請輸入保質期時長",
	en: "",
}, {
	id: 223,
	cn: "出库策略",
	tc: "出庫策略",
	en: "",
}, {
	id: 224,
	cn: "请选择出库策略",
	tc: "請選擇出庫策略",
	en: "",
}, {
	id: 225,
	cn: "批次控制",
	tc: "批次控制",
	en: "",
}, {
	id: 226,
	cn: "库存警告",
	tc: "庫存警告",
	en: "",
}, {
	id: 227,
	cn: "库存上限",
	tc: "庫存上限",
	en: "",
}, {
	id: 228,
	cn: "请输入库存上限",
	tc: "請輸入庫存上限",
	en: "",
}, {
	id: 229,
	cn: "库存下限",
	tc: "庫存下限",
	en: "",
}, {
	id: 230,
	cn: "请输入库存下限",
	tc: "請輸入庫存下限",
	en: "",
}, {
	id: 240,
	cn: "预警时间",
	tc: "預警時間",
	en: "",
}, {
	id: 241,
	cn: "请输入预警时间",
	tc: "請輸入預警時間",
	en: "",
}, {
	id: 242,
	cn: "请选择计量单位",
	tc: "請選擇計量單位",
	en: "",
}, {
	id: 243,
	cn: "请输入单价",
	tc: "請輸入單價",
	en: "",
}, {
	id: 244,
	cn: "请选择是否启用保质期",
	tc: "請選擇是否啟用保質期",
	en: "",
}, {
	id: 245,
	cn: "请选择是否启用批次控制",
	tc: "請選擇是否啟用批次控制",
	en: "",
}, {
	id: 246,
	cn: "请选择是否启用库存警告",
	tc: "請選擇是否啟用庫存警告",
	en: "",
}, {
	id: 247,
	cn: "图片格式只能为jpeg格式或者png格式",
	tc: "圖片格式只能為jpeg格式或者png格式",
	en: "",
}, {
	id: 248,
	cn: "临期预警",
	tc: "臨期預警",
	en: "",
}, {
	id: 249,
	cn: "物料名称",
	tc: "物料名稱",
	en: "",
}, {
	id: 250,
	cn: "批次编号",
	tc: "批次編號",
	en: "",
}, {
	id: 251,
	cn: "序号",
	tc: "序號",
	en: "",
}, {
	id: 252,
	cn: "生产日期",
	tc: "生產日期",
	en: "",
}, {
	id: 253,
	cn: "库存数量",
	tc: "庫存數量",
	en: "",
}, {
	id: 254,
	cn: "存储仓库",
	tc: "存儲倉庫",
	en: "",
}, {
	id: 255,
	cn: "库存预警",
	tc: "庫存預警",
	en: "",
}, {
	id: 256,
	cn: "库存状况",
	tc: "庫存狀況",
	en: "",
}, {
	id: 257,
	cn: "PDA管理",
	tc: "PDA管理",
	en: "",
}, {
	id: 258,
	cn: "请选择PDA",
	tc: "請選擇PDA",
	en: "",
}, {
	id: 259,
	cn: "关联员工",
	tc: "關聯員工",
	en: "",
}, {
	id: 260,
	cn: "关联账号",
	tc: "關聯帳號",
	en: "",
}, {
	id: 261,
	cn: "打印机管理",
	tc: "打印機管理",
	en: "",
}, {
	id: 262,
	cn: "设备SN",
	tc: "設備SN",
	en: "",
}, {
	id: 263,
	cn: "设备名称",
	tc: "設備名稱",
	en: "",
}, {
	id: 264,
	cn: "请输入设备名称",
	tc: "請輸入設備名稱",
	en: "",
}, {
	id: 265,
	cn: "请输入设备SN",
	tc: "請輸入設備SN",
	en: "",
}, {
	id: 266,
	cn: "请输入设备AppKey",
	tc: "請輸入設備AppKey",
	en: "",
}, {
	id: 267,
	cn: "设备AppKey",
	tc: "設備AppKey",
	en: "",
}, {
	id: 268,
	cn: "请选择打印机",
	tc: "请选择打印机",
	en: "",
}, {
	id: 269,
	cn: "设备ID",
	tc: "設備ID",
	en: "",
}, {
	id: 270,
	cn: "设备名称",
	tc: "設備名稱",
	en: "",
}, {
	id: 271,
	cn: "设备IP",
	tc: "設備IP",
	en: "",
}, {
	id: 272,
	cn: "请选择设备",
	tc: "請選擇設備",
	en: "",
}, {
	id: 273,
	cn: "设备回访地址",
	tc: "設備回訪地址",
	en: "",
}, {
	id: 274,
	cn: "查看",
	tc: "查看",
	en: "",
}, {
	id: 275,
	cn: "请输入设备IP",
	tc: "請輸入設備IP",
	en: "",
}, {
	id: 276,
	cn: "摄像头管理",
	tc: "攝像頭管理",
	en: "",
}, {
	id: 277,
	cn: "人脸设备",
	tc: "人臉設備",
	en: "",
}, {
	id: 278,
	cn: "开始日期",
	tc: "開始日期",
	en: "",
}, {
	id: 279,
	cn: "结束日期",
	tc: "結束日期",
	en: "",
}, {
	id: 280,
	cn: "创建时间",
	tc: "創建時間",
	en: "",
}, {
	id: 281,
	cn: "设备编号",
	tc: "設備編离线號",
	en: "",
}, {
	id: 281,
	cn: "在线状态",
	tc: "線上狀態",
	en: "",
}, {
	id: 282,
	cn: "在线",
	tc: "在線",
	en: "",
}, {
	id: 283,
	cn: "离线",
	tc: "離線",
	en: "",
}, {
	id: 284,
	cn: "人员通行证列表",
	tc: "人員通行證列錶",
	en: "",
}, {
	id: 285,
	cn: "人员通行记录",
	tc: "人員通行記錄",
	en: "",
}, {
	id: 286,
	cn: "时间",
	tc: "時間",
	en: "",
}, {
	id: 287,
	cn: "姓名",
	tc: "姓名",
	en: "",
}, {
	id: 288,
	cn: "人脸照片",
	tc: "人臉照片",
	en: "",
}, {
	id: 289,
	cn: "IC卡号",
	tc: "IC卡號",
	en: "",
}, {
	id: 290,
	cn: "人员姓名",
	tc: "人員姓名",
	en: "",
}, {
	id: 291,
	cn: "有效期",
	tc: "有效期",
	en: "",
}, {
	id: 292,
	cn: "通行方式",
	tc: "通行方式",
	en: "",
}, {
	id: 293,
	cn: "人脸",
	tc: "人臉",
	en: "",
}, {
	id: 294,
	cn: "IC卡",
	tc: "IC卡",
	en: "",
}, {
	id: 295,
	cn: "请选择人员通行证",
	tc: "請選擇人員通行證",
	en: "",
}, {
	id: 296,
	cn: "托盘管理",
	tc: "託盤管理",
	en: "",
}, {
	id: 297,
	cn: "托盘编码",
	tc: "託盤編碼",
	en: "",
}, {
	id: 298,
	cn: "托盘类型",
	tc: "託盤類型",
	en: "",
}, {
	id: 299,
	cn: "请选择类型",
	tc: "請選擇類型",
	en: "",
}, {
	id: 300,
	cn: "请选择托盘",
	tc: "請選擇託盤",
	en: "",
}, {
	id: 301,
	cn: "请输入托盘名称",
	tc: "請輸入託盤名稱",
	en: "",
}, {
	id: 302,
	cn: "托盘分类",
	tc: "託盤分類",
	en: "",
}, {
	id: 303,
	cn: "箱码管理",
	tc: "箱碼管理",
	en: "",
}, {
	id: 304,
	cn: "箱子编码",
	tc: "箱子編碼",
	en: "",
},  {
	id: 305,
	cn: "批量打印箱码",
	tc: "批量打印箱碼",
	en: "",
}, {
	id: 306,
	cn: "装箱",
	tc: "裝箱",
	en: "",
}, {
	id: 307,
	cn: "所在位置",
	tc: "所在位置",
	en: "",
}, {
	id: 308,
	cn: "箱内物料数量",
	tc: "箱內物料數量",
	en: "",
}, {
	id: 309,
	cn: "生成数量",
	tc: "生成數量",
	en: "",
}, {
	id: 310,
	cn: "物料列表",
	tc: "物料列表",
	en: "",
}, {
	id: 311,
	cn: "请输入数量",
	tc: "請輸入數量",
	en: "",
}, {
	id: 312,
	cn: "请选择箱码",
	tc: "請選擇箱碼",
	en: "",
}, {
	id: 313,
	cn: "选择物料",
	tc: "選擇物料",
	en: "",
}, {
	id: 314,
	cn: "数量",
	tc: "數量",
	en: "",
}, {
	id: 315,
	cn: "该物料库存不为0，暂不可删除",
	tc: "該物料庫存不為0，暫不可删除",
	en: "",
}, {
	id: 316,
	cn: "箱码详情",
	tc: "箱碼詳情",
	en: "",
}, {
	id: 317,
	cn: "箱内物料总数量",
	tc: "箱內物料總數量",
	en: "",
}, {
	id: 318,
	cn: "物料信息",
	tc: "物料信息",
	en: "",
}, {
	id: 319,
	cn: "物料图片",
	tc: "物料圖片",
	en: "",
}, {
	id: 320,
	cn: "单位名称",
	tc: "物料圖片",
	en: "",
}, {
	id: 321,
	cn: "请输入单位名称",
	tc: "請輸入單位名稱",
	en: "",
}, {
	id: 322,
	cn: "人员类型",
	tc: "人員類型",
	en: "",
}, {
	id: 323,
	cn: "二维码",
	tc: "二維碼",
	en: "",
}, {
	id: 324,
	cn: "通行类型",
	tc: "通行類型",
	en: "",
}, {
	id: 325,
	cn: "有限期内通行",
	tc: "有限期內通行",
	en: "",
}, {
	id: 326,
	cn: "无限制",
	tc: "無限制",
	en: "",
}, {
	id: 327,
	cn: "请选择通行人员",
	tc: "請選擇通行人員",
	en: "",
}, {
	id: 328,
	cn: "请输入IC卡号",
	tc: "請輸入IC卡號",
	en: "",
}, {
	id: 329,
	cn: "示例",
	tc: "示例",
	en: "",
}, {
	id: 330,
	cn: "图片大小须小于300KB",
	tc: "圖片大小須小於300KB",
	en: "",
}, {
	id: 331,
	cn: "图片需上传员工正脸照片",
	tc: "圖片需上傳員工正臉照片",
	en: "",
}, {
	id: 332,
	cn: "人脸图片",
	tc: "人臉圖片",
	en: "",
}, {
	id: 333,
	cn: "重新拍摄",
	tc: "重新拍攝",
	en: "",
}, {
	id: 334,
	cn: "入库通知单",
	tc: "入庫通知單",
	en: "",
}, {
	id: 335,
	cn: "入库单号",
	tc: "入庫單號",
	en: "",
}, {
	id: 336,
	cn: "供应商",
	tc: "供應商",
	en: "",
}, {
	id: 337,
	cn: "入库类型",
	tc: "入庫類型",
	en: "",
}, {
	id: 338,
	cn: "订单来源",
	tc: "訂單來源",
	en: "",
}, {
	id: 339,
	cn: "创建人",
	tc: "創建人",
	en: "",
}, {
	id: 340,
	cn: "操作员",
	tc: "操作員",
	en: "",
}, {
	id: 341,
	cn: "创建入库单",
	tc: "創建入庫單",
	en: "",
}, {
	id: 342,
	cn: "请选择入库类型",
	tc: "請選擇入庫類型",
	en: "",
}, {
	id: 343,
	cn: "请选择订单来源",
	tc: "請選擇訂單來源",
	en: "",
}, {
	id: 344,
	cn: "关联单号",
	tc: "關聯單號",
	en: "",
}, {
	id: 345,
	cn: "请输入关联单号",
	tc: "請輸入關聯單號",
	en: "",
}, {
	id: 350,
	cn: "操作员",
	tc: "操作員",
	en: "",
}, {
	id: 351,
	cn: "请选择操作员",
	tc: "請選擇操作員",
	en: "",
}, {
	id: 352,
	cn: "用户",
	tc: "用戶",
	en: "",
}, {
	id: 353,
	cn: "请选择用户",
	tc: "請選擇用戶",
	en: "",
}, {
	id: 354,
	cn: "请选择用户类型",
	tc: "請選擇用戶類型",
	en: "",
}, {
	id: 355,
	cn: "入库数量",
	tc: "入庫數量",
	en: "",
}, {
	id: 356,
	cn: "选择客户",
	tc: "選擇客戶",
	en: "",
}, {
	id: 357,
	cn: "选择供应商",
	tc: "選擇供應商",
	en: "",
}, {
	id: 358,
	cn: "请完善物料信息",
	tc: "請完善物料信息",
	en: "",
}, {
	id: 359,
	cn: "入库单详情",
	tc: "入庫單詳情",
	en: "",
}, {
	id: 360,
	cn: "打印入库单",
	tc: "打印入庫單",
	en: "",
}, {
	id: 361,
	cn: "预报数量",
	tc: "預報數量",
	en: "",
}, {
	id: 362,
	cn: "出库通知单",
	tc: "出庫通知單",
	en: "",
}, {
	id: 363,
	cn: "出库单号",
	tc: "出庫單號",
	en: "",
}, {
	id: 364,
	cn: "出库类型",
	tc: "出庫類型",
	en: "",
}, {
	id: 365,
	cn: "创建出库单",
	tc: "创建出库单",
	en: "",
}, {
	id: 366,
	cn: "是否亮灯",
	tc: "是否亮燈",
	en: "",
}, {
	id: 367,
	cn: "请选择出库类型",
	tc: "請選擇出庫類型",
	en: "",
}, {
	id: 368,
	cn: "移库管理",
	tc: "移庫管理",
	en: "",
}, {
	id: 369,
	cn: "移库单号",
	tc: "移庫單號",
	en: "",
}, {
	id: 370,
	cn: "客户",
	tc: "客戶",
	en: "",
}, {
	id: 371,
	cn: "通过",
	tc: "通過",
	en: "",
}, {
	id: 372,
	cn: "拒绝",
	tc: "拒絕",
	en: "",
}, ];

const ShowText = (params : any) => {
	let language : any = localStorage.getItem("language");
	if (!language) {
		language = 1;
	}
	let lan = ''
	if (language == 1) {
		lan = 'cn'
	} else if (language == 2) {
		lan = 'tc'
	} else if (language == 3) {
		lan = 'en'
	}
	let txt = "";
	TextList.forEach((v : any) => {
		if (v.id == params) {
			txt = v[lan];
		}
	});
	return txt;
};

export default {
	ShowText,
};