<template>
	<div>
		<el-button class="cancel-btn" @click="close">取消</el-button>
	</div>
</template>

<script lang="ts" setup>
	import { defineEmits, getCurrentInstance } from 'vue';

	const { proxy } = getCurrentInstance() as any;
	const emits = defineEmits(["change"]);

	const close = () => {
		emits("change");
	};
</script>

<style lang="scss" scoped>
	.cancel-btn {
		width: 100px;
		height: 40px;
		border-radius: 4px;
		font-size: 14px;
		line-height: 40px;
	}
</style>