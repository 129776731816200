<template>
	<div>
		<el-button class="print-btn" type="primary" v-print="print" v-if="PrintArr.length > 0">
			<span class="fz-14 lh-36">{{text}}</span>
		</el-button>
		<el-button class="print-btn" type="primary" @click="StartPrint" v-else>
			<span class="fz-14 lh-36">{{text}}</span>
		</el-button>
		<div class="fixed print-box">
			<div id="printBox">
				<!-- <div class="flex-row-center-start" style="page-break-after:always;height:36mm;"
					v-for="(v, i) in PrintArr" :key="i">
					<div class="flex-row-center-start ali-stretch">
						<img class="qrcode_1_1" :src="v.barcode_url" alt="">
						<div class="flex-1 right flex-column-sb font-blod">
							<div class="title-name">
								<div>库区：</div>
								<div class="one-row">{{v.area_name}}</div>
							</div>
							<div class="title-name">
								<div>库位：</div>
								<div class="one-row">
									{{v.shelves_sn}}货架{{v.rows < 10 ? `0${v.rows}` : v.rows}}层{{v.columns < 10 ? `0${v.columns}` : v.columns}}库位
								</div>
							</div>
							<div class="title-name">
								<div>属性：</div>
								<div class="one-row">{{v.type_text}}</div>
							</div>
						</div>
					</div>
				</div> -->
				<!-- <div class="flex-row-center-start" style="height: 36mm; page-break-after:always" v-for="(v, i) in PrintArr" :key="i">
					<div class="flex-row-center-start ali-stretch">
						<img class="qrcode_1_1" :src="v.barcode_url" alt="">
						<div class="flex-1 right flex-column-sb">
							<div class="title-name">库区：{{v.area_name}}</div>
							<div class="title-name">
								库位：{{v.shelves_sn}}货架{{v.rows < 10 ? `0${v.rows}` : v.rows}}层{{v.columns < 10 ? `0${v.columns}` : v.columns}}库位
							</div>
							<div class="title-name">
								属性：{{v.type_text}}
							</div>
						</div>
					</div>
				</div> -->
				<div class="flex-column-center-center" style="page-break-after: always; height:24mm;"
					v-for="(v, i) in PrintArr" :key="i">
					<img class="qrcode_1_1" :src="v.barcode_url" alt="">
					<div class="font-blod" style="min-width: 30mm; margin-top: 1mm;">
						<div class="title-name flex-row">
							<div>库区：</div>
							<div class="one-row">{{v.area_name}}</div>
						</div>
						<div class="title-name flex-row" style="margin-top: 0.5mm;">
							<div>库位：</div>
							<div class="flex-1">
								{{v.shelves_sn}}-{{v.rows < 10 ? `0${v.rows}` : v.rows}}-{{v.columns < 10 ? `0${v.columns}` : v.columns}}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
	import { defineProps, ref, watch, getCurrentInstance } from 'vue';

	const props = defineProps({
		text: {
			type: String,
			default: "",
		},
		SelectArr: {
			type: Array,
			default: function () {
				return []
			},
		}
	});

	const { proxy } = getCurrentInstance() as any;
	const PrintArr = ref<any>([]);
	const print = ref<any>({
		id: 'printBox',//这里的id就是上面我们的打印区域id，实现指哪打哪
		popTitle: '', // 打印配置页上方的标题
		extraCss: "", // 打印可引入外部的一个 css 文件
		extraHead: ' ', // 最上方的头部文字，附加在head标签上的额外标签，使用逗号分割
		preview: false, // 是否启动预览模式，默认是false
		previewTitle: ' ', // 打印预览的标题
		previewPrintBtnLabel: ' ', // 打印预览的标题下方的按钮文本，点击可进入打印
		zIndex: 20002, // 预览窗口的z-index，默认是20002，最好比默认值更高
	});

	watch(() => props.SelectArr, (n : any) => {
		PrintArr.value = n;
	}, {
		immediate: true,
	});

	const StartPrint = () => {
		proxy.$message.error("请选择库位");
	};
</script>

<style lang="scss" scoped>
	.print-btn {
		height: 36px;
		border-radius: 4px;
	}

	.print-box {
		top: -800px;
		left: -1000000px;
	}

	// @media print {
	// 	@page {
	// 		size: auto;
	// 		margin: 4mm;
	// 		margin-left: 8mm;
	// 	}

	// 	body,
	// 	html {
	// 		height: auto !important;
	// 	}

	// 	#printBox {
	// 		height: 100%;
	// 	}

	// 	#printBox .sn {
	// 		font-size: 5mm;
	// 	}

	// 	#printBox .qrcode_1_1 {
	// 		width: 30mm;
	// 		height: 30mm;
	// 	}

	// 	#printBox .right {
	// 		margin-left: 1.5mm;
	// 		padding-top: 1.5mm;
	// 		padding-bottom: 1.5mm;
	// 	}

	// 	#printBox .title-name {
	// 		font-size: 3.5mm;
	// 	}

	// 	#printBox .bottom {
	// 		margin-top: 4mm;
	// 	}
	// }
	
	@media print {
		@page {
			size: auto;
			margin: 2mm;
			margin-left: 4mm;
		}
	
		body,
		html {
			height: auto !important;
		}
	
		#printBox {
			height: 100%;
		}
	
		#printBox .qrcode_1_1 {
			width: 15mm;
			height: 15mm;
		}
	
		#printBox .right {
			margin-left: 1mm;
		}
	
		#printBox .title-name {
			font-size: 1.8mm;
		}
	}
</style>