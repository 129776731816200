<template>
	<div class="pointer plr-10 co-0066FF">
		<div @click="Operate">通过</div>
	</div>
</template>

<script lang="ts" setup>
	import { defineEmits, getCurrentInstance } from 'vue';
	
	const { proxy } = getCurrentInstance() as any;
	const emits = defineEmits(["change"]);
	
	const Operate = () => {
		emits("change");
	}
</script>

<style lang="scss" scoped>
</style>