<template>
	<div class="w-100">
		<el-select remote-show-suffix filterable reserve-keyword clearable :style="{'width': `${width}`}"
			:disabled="disabled" :size="size" v-model="UserId" :loading="loading"
			remote @focus="SelectFocus" :placeholder="placeholder" @change="WarehouseChange" @clear="WarehouseClear">
			<el-option v-for="v in OperatorList" :key="v[ValueKey]" :label="v[LabelKey]" :value="v[ValueKey]" />
		</el-select>
	</div>
</template>

<script lang="ts" setup>
	import { ref, getCurrentInstance, defineEmits, defineProps, withDefaults, onMounted, watch } from 'vue';

	interface main {
		id ?: string | number,
		size ?: string,
		placeholder ?: string,
		ValueKey ?: string,
		LabelKey ?: string,
		width ?: string | number,
		disabled ?: boolean,
		IsAuto ?: boolean,
	};

	const props = withDefaults(defineProps<main>(), {
		id: "",
		size: "default",
		placeholder: "请选择操作员",
		ValueKey: "value",
		LabelKey: "label",
		width: "",
		disabled: false,
		IsAuto: false,
	});

	const { proxy } = getCurrentInstance() as any;
	const emits = defineEmits(["update:id", "change", "clear"]);
	const UserId = ref<any>("");
	const OperatorList = ref<any>([]);
	const loading = ref(false);

	watch(() => props.id, (n : any) => {
		UserId.value = n;
	});

	onMounted(() => {
		UserId.value = props.id;
		if (props.IsAuto) {
			if (proxy.$store.state.IsRefreshOperatorList) {
				GetData();
			} else {
				if (proxy.$store.state.OperatorList.length > 0) {
					OperatorList.value = proxy.$store.state.OperatorList;
				} else {
					GetData();
				}
			}
		}
	});

	const WarehouseChange = (data : any) => {
		emits("update:id", data);
		emits("change", data);
	};
	const WarehouseClear = () => {
		emits("clear");
	};
	const SelectFocus = () => {
		if (proxy.$store.state.IsRefreshOperatorList) {
			GetData();
		} else {
			if (proxy.$store.state.OperatorList.length > 0) {
				OperatorList.value = proxy.$store.state.OperatorList;
			} else {
				GetData();
			}
		}
	};
	const GetData = () => {
		proxy.$api.tokenRequest({
			url: '/admin/admin',
			data: {
				nickname: "",
				phone: "",
				email: "",
				warehouse_id: "",
				auth_id: 1,
				rfid: "",
				is_page: 0,
			},
			success: (res : any) => {
				OperatorList.value = res.data;
				proxy.$store.commit("GetAllList", {
					type: 26,
					data: res.data,
				});
			},
		});
	}
</script>

<style>
</style>