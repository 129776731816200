<template>
	<div class="flex-row-center-start fz-18 font-blod">
		<div class="flex-row-center-start mr-40" v-for="(v, i) in data.arr" :key="i">
			<div class="color-primary">{{v.name}}：</div>
			<div class="font-blod co-0066FF" v-if="data.api">{{details[v.key]}}</div>
			<div class="font-blod co-0066FF" v-else>{{v.key}}</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
	import { ref, defineProps, getCurrentInstance, defineExpose } from 'vue';
	
	const props = defineProps({
		data: {
			type: Object,
			default: function() {
				return {}
			}
		}
	});
	
	const { proxy } = getCurrentInstance() as any;
	const details = ref({});
	
	const RefreshCount = () => {
		if (props.data.api) {
			proxy.$api.tokenRequest({
				url: props.data.api,
				data: props.data.data,
				success: (res : any) => {
					console.log(res);
					details.value = res.data;
				},
			});
		}
	};
	
	defineExpose({
		RefreshCount,
	});
</script>

<style>
</style>