<template>
	<el-select remote-show-suffix filterable clearable :style="{'width': `${width}`}" :disabled="disabled"
		:size="size" v-model="ShelvesId" :loading="loading" @focus="SelectFocus" :placeholder="placeholder"
		@change="ShelvesChange" @clear="ShelvesClear">
		<el-option v-for="v in ShelvesList" :key="v[ValueKey]" :label="v[LabelKey]" :value="v[ValueKey]" />
	</el-select>
</template>

<script lang="ts" setup>
	import { ref, getCurrentInstance, defineEmits, defineProps, withDefaults, onMounted, watch } from 'vue';

	interface main {
		id ?: string | number,
		size ?: string,
		ValueKey ?: string,
		LabelKey ?: string,
		width ?: string | number,
		disabled ?: boolean,
		IsAuto ?: boolean,
		WarehouseId ?: string | number,
		AreaId ?: string | number,
		RoadwayId ?: string | number,
	};

	const props = withDefaults(defineProps<main>(), {
		id: "",
		size: "default",
		ValueKey: "value",
		LabelKey: "label",
		width: "100%",
		disabled: false,
		IsAuto: false,
		WarehouseId: "",
		AreaId: "",
		RoadwayId: "",
	});

	const { proxy } = getCurrentInstance() as any;
	const emits = defineEmits(["update:id", "change", "clear"]);
	const ShelvesId = ref<any>("");
	const ShelvesList = ref<any>([]);
	const loading = ref(false);
	const placeholder = ref("请选择货架");

	watch(() => props.id, (n : any) => {
		ShelvesId.value = n;
	});

	onMounted(() => {
		ShelvesId.value = props.id;
		if (props.IsAuto) {
			if (proxy.$store.state.IsRefreshShelves) {
				GetData();
			} else {
				if (proxy.$store.state.ShelvesInfo) {
					if (proxy.$store.state.ShelvesInfo.wid == props.WarehouseId && proxy.$store.state.ShelvesInfo.aid == props.AreaId && proxy.$store.state.ShelvesInfo.rid == props.RoadwayId) {
						ShelvesList.value = proxy.$store.state.ShelvesInfo.list;
					} else {
						GetData();
					}
				} else {
					GetData();
				}
			}
		}
	});

	const ShelvesChange = (data : any) => {
		emits("update:id", data);
		emits("change", data);
	};
	const ShelvesClear = () => {
		emits("clear");
	};
	const SelectFocus = () => {
		if (proxy.$store.state.IsRefreshShelves) {
			GetData();
		} else {
			if (proxy.$store.state.ShelvesInfo) {
				if (proxy.$store.state.ShelvesInfo.wid == props.WarehouseId && proxy.$store.state.ShelvesInfo.aid == props.AreaId && proxy.$store.state.ShelvesInfo.rid == props.RoadwayId) {
					ShelvesList.value = proxy.$store.state.ShelvesInfo.list;
				} else {
					GetData();
				}
			} else {
				GetData();
			}
		}
	};
	const GetData = () => {
		loading.value = true;
		proxy.$api.tokenRequest({
			url: '/admin/warehouse/listByType',
			data: {
				type: 4,
				warehouse_id: props.WarehouseId,
				area_id: props.AreaId,
				road_id: props.RoadwayId,
				is_page: 0,
			},
			success: (res : any) => {
				loading.value = false;
				ShelvesList.value = res.data;
				proxy.$store.commit("GetAllList", {
					type: 11,
					wid: props.WarehouseId,
					aid: props.AreaId,
					rid:  props.RoadwayId,
					list: res.data,
				});
			},
			fail: () => {
				loading.value = false;
			},
		});
	};
</script>

<style>
</style>