import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes : Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'login',
		meta: {
			title: '登录',
		},
		component: () => import('../views/LoginView.vue')
	},
	{
		path: '/Home',
		name: 'home',
		component: () => import('../views/HomeView.vue'),
		children: [{
			path: '/Index',
			name: 'Index',
			meta: {
				title: '数据统计',
			},
			component: () => import('../views/IndexView.vue'),
		}, {
			path: '/BasicInfo/StorageAreaType',
			name: 'StorageAreaType',
			meta: {
				title: '库区类型',
			},
			component: () => import('../views/BasicInfo/StorageAreaType.vue'),
		}, {
			path: '/BasicInfo/LocationType',
			name: 'LocationType',
			meta: {
				title: '库位类型',
			},
			component: () => import('../views/BasicInfo/LocationType.vue'),
		}, {
			path: '/WarehouseManage/WarehouseAreaManage',
			name: 'WarehouseAreaManage',
			meta: {
				title: '库区管理',
			},
			component: () => import('../views/Warehouse/WarehouseAreaManage.vue'),
		}, {
			path: '/WarehouseManage/RoadwayManage',
			name: 'RoadwayManage',
			meta: {
				title: '巷道管理',
			},
			component: () => import('../views/Warehouse/RoadwayManage.vue'),
		}, {
			path: '/WarehouseManage/ShelvesManage',
			name: 'ShelvesManage',
			meta: {
				title: '货架管理',
			},
			component: () => import('../views/Warehouse/ShelvesManage.vue'),
		}, {
			path: '/WarehouseManage/WarehouseLocationManage',
			name: 'WarehouseLocationManage',
			meta: {
				title: '库位管理',
			},
			component: () => import('../views/Warehouse/WarehouseLocationManage.vue'),
		}, {
			path: '/GoodManage/Category',
			name: 'Category',
			meta: {
				title: '商品类型',
			},
			component: () => import('../views/GoodManage/Category.vue'),
		}, {
			path: '/GoodManage/GoodManage',
			name: 'GoodManage',
			meta: {
				title: '商品管理',
			},
			component: () => import('../views/GoodManage/GoodManage.vue'),
		}, {
			path: '/GoodManage/UnitOfMeasurement',
			name: 'UnitOfMeasurement',
			meta: {
				title: '计量单位',
			},
			component: () => import('../views/GoodManage/UnitOfMeasurement.vue'),
		}, {
			path: '/GoodManage/InventoryAlert',
			name: 'InventoryAlert',
			meta: {
				title: '库存预警',
			},
			component: () => import('../views/GoodManage/InventoryAlert.vue'),
		}, {
			path: '/HardwareManage/PdaManage',
			name: 'PdaManage',
			meta: {
				title: 'PDA管理',
			},
			component: () => import('../views/HardwareManage/PdaManage.vue'),
		}, {
			path: '/HardwareManage/LanelightsManage',
			name: 'LanelightsManage',
			meta: {
				title: '巷道灯管理',
			},
			component: () => import('../views/HardwareManage/LanelightsManage.vue'),
		}, {
			path: '/HardwareManage/LightingLabelManage',
			name: 'LightingLabelManage',
			meta: {
				title: 'PTL管理',
			},
			component: () => import('../views/HardwareManage/LightingLabelManage.vue'),
		}, {
			path: '/WarehouseOperations/InWarehouseManage',
			name: 'InWarehouseManage',
			meta: {
				title: '入库管理',
			},
			component: () => import('../views/WarehouseOperations/InWarehouseManage.vue'),
		}, {
			path: '/WarehouseOperations/OutWarehouseManage',
			name: 'OutWarehouseManage',
			meta: {
				title: '出库管理',
			},
			component: () => import('../views/WarehouseOperations/OutWarehouseManage.vue'),
		}, {
			path: '/WarehouseOperations/InventoryManage',
			name: 'InventoryManage',
			meta: {
				title: '盘点管理',
			},
			component: () => import('../views/WarehouseOperations/InventoryManage.vue'),
		}, {
			path: '/WarehouseOperations/RelocationManage',
			name: 'RelocationManage',
			meta: {
				title: '移库管理',
			},
			component: () => import('../views/WarehouseOperations/RelocationManage.vue'),
		}, {
			path: '/ReportManager/InventoryDetails',
			name: 'InventoryDetails',
			meta: {
				title: '库存明细',
			},
			component: () => import('../views/ReportManager/InventoryDetails.vue'),
		}, {
			path: '/ReportManager/InventoryReport',
			name: 'InventoryReport',
			meta: {
				title: '库存报表',
			},
			component: () => import('../views/ReportManager/InventoryReport.vue'),
		}, {
			path: '/ReportManager/InventoryLedger',
			name: 'InventoryLedger',
			meta: {
				title: '库存台账',
			},
			component: () => import('../views/ReportManager/InventoryLedger.vue'),
		}, {
			path: '/ReportManager/InWarehouseDetails',
			name: 'InWarehouseDetails',
			meta: {
				title: '入库明细',
			},
			component: () => import('../views/ReportManager/InWarehouseDetails.vue'),
		}, {
			path: '/ReportManager/OutWarehouseDetails',
			name: 'OutWarehouseDetails',
			meta: {
				title: '出库明细',
			},
			component: () => import('../views/ReportManager/OutWarehouseDetails.vue'),
		}, {
			path: '/ReportManager/WarehouseLocationPlan',
			name: 'WarehouseLocationPlan',
			meta: {
				title: '库位平面图',
			},
			redirect: { name: 'WarehouseAreaPlan' },
			component: () => import('../views/ReportManager/WarehouseLocationPlan.vue'),
			children: [{
				path: '/ReportManager/WarehouseAreaPlan',
				name: 'WarehouseAreaPlan',
				meta: {
					title: '库位平面图',
				},
				component: () => import('../views/ReportManager/WarehouseAreaPlan.vue'),
			}, {
				path: '/ReportManager/ShelfPlan',
				name: 'ShelfPlan',
				meta: {
					title: '库位平面图',
				},
				component: () => import('../views/ReportManager/ShelfPlan.vue'),
			}, {
				path: '/ReportManager/LocationPlan',
				name: 'LocationPlan',
				meta: {
					title: '库位平面图',
				},
				component: () => import('../views/ReportManager/LocationPlan.vue'),
			}]
		}, {
			path: '/SystemConfig/SystemMenuList',
			name: 'SystemMenuList',
			meta: {
				title: '系统菜单管理',
			},
			component: () => import('../views/SystemManage/SystemMenuList.vue'),
		}, {
			path: '/SystemConfig/UserAccessControl',
			name: 'UserAccessControl',
			meta: {
				title: '权限管理',
			},
			component: () => import('../views/SystemManage/UserAccessControl.vue'),
		}, {
			path: '/SystemConfig/StaffManage',
			name: 'StaffManage',
			meta: {
				title: '员工管理',
			},
			component: () => import('../views/SystemManage/StaffManage.vue'),
		}, {
			path: '/SystemConfig/WarehouseAuth',
			name: 'WarehouseAuth',
			meta: {
				title: '仓库权限',
			},
			component: () => import('../views/SystemManage/WarehouseAuth.vue'),
		}],
	}
]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

/* 路由发生变化修改页面title */
router.beforeEach((to, from, next) => {
	if (to.meta.title) {
		document.title = "深圳康瑞通PTL智慧仓储管理系统";
	}
	next();
})

export default router