<template>
	<el-select remote-show-suffix :style="{'width': `${width}`}" :disabled="disabled" :size="size" v-model="StatusId"
		:placeholder="placeholder" @change="StatusChange" @clear="StatusClear">
		<el-option v-for="v in StatusList" :key="v[ValueKey]" :label="v[LabelKey]" :value="v[ValueKey]" />
	</el-select>
</template>

<script lang="ts" setup>
	import { ref, defineEmits, defineProps, withDefaults, onMounted, watch, getCurrentInstance } from 'vue';

	interface main {
		id ?: string | number,
		size ?: string,
		ValueKey ?: string,
		LabelKey ?: string,
		width ?: string | number,
		disabled ?: boolean,
	};

	const props = withDefaults(defineProps<main>(), {
		id: "",
		size: "default",
		ValueKey: "value",
		LabelKey: "label",
		width: "100%",
		disabled: false,
	});
	
	const { proxy } = getCurrentInstance() as any;
	const emits = defineEmits(["update:id", "change", "clear"]);
	const StatusId = ref<any>("");
	const StatusList = ref<any>([{
		value: 1,
		label: "启用",
	}, {
		value: 0,
		label: "禁用",
	}]);
	const placeholder = ref("请选择状态");

	watch(() => props.id, (n : any) => {
		StatusId.value = n;
	});

	onMounted(() => {
		StatusId.value = props.id;
	});

	const StatusChange = (data : any) => {
		emits("update:id", data);
		emits("change", data);
	};
	const StatusClear = () => {
		emits("clear");
	};
</script>

<style>
</style>