import { ref } from 'vue';
import config from '@/api/config';

let timer : any = null;
let webSocket : any = {
	url: config.websocket.url, // websocket的url和端口
	heartbeat: config.websocket.heartbeat * 1000, // 心跳时间，秒
	maxReconnectionNum: config.websocket.maxReconnectionNum || 15, // 失败或错误后，最大重连次数
	action: config.websocket.action,
	username: null,
	password: null,
	socket: null,
	is_logged: false, // 是否已登录
	num: 0, // 重新连接的次数
	loading: false, // websocket连接登录中
	timer: null, // 心跳包定时器
	debug: true, // 是否打印数据
	development: false, // 是否是开发环境，编译时会自动判断
	no_token_reconnection_num: 0, // 没有token时重新连接的次数
};

// 初始化 WebSocket
webSocket.InitWebSocket = (params : any) => {
	// 自动添加所有字段到WebSocket中
	for (let key in params) {
		webSocket[key] = params[key];
	}
};

// 打开 WebSocket
webSocket.open = (callback : any) => {
	if (webSocket.loading) return false;
	webSocket.loading = true; // 正在连接
	let token = localStorage.getItem("userInfo");
	if (!token) {
		console.log('没token,webSocket连接打开失败');
		return false;
	}
	// if (webSocket.no_token_reconnection_num < 10) {
	// 	webSocket.no_token_reconnection_num++;
	// 	let a = setTimeout(() => {
	// 		clearTimeout(a);
	// 		webSocket.loading = false;
	// 		open(params);
	// 	}, 2000);
	// } else {
	// 	webSocket.loading = false;
	// 	webSocket.no_token_reconnection_num = 0;
	// }
	// 清除心跳包定时器
	timer && clearTimeout(timer);
	webSocket.socket = new WebSocket(`${webSocket.url}?username=${webSocket.username}&password=${webSocket.password}&type=2`);
	// 监听 WebSocket 连接打开事件
	webSocket.socket.onopen = () => {
		console.log('webSocket连接打开');
	};
	// 监听 WebSocket 接受到服务器的消息事件
	webSocket.socket.onmessage = (res : any) => {
		if (res) {
			let result = JSON.parse(res.data);
			console.log("接收消息", result);
			switch (result.action) {
				case webSocket.action.ping:
					break;
				case "error":
					console.log("错误信息" + JSON.stringify(result));
					break;
				case webSocket.action.login:
					// 登录
					if (result.code == 0) {
						// 登录成功
						console.log("登录成功");
						webSocket.num = 0;
						callback && typeof callback === "function" && callback();
						webSocket.onLoginSuccess && typeof webSocket.onLoginSuccess === "function" && webSocket.onLoginSuccess(result);
						let a = setTimeout(() => {
							clearTimeout(a);
							webSocket.checkConnect(); // 开启心跳包
						}, webSocket.heartbeat)
					} else {
						console.log("登录返回信息" + JSON.stringify(result));
					}
					break;
				case "faceAddUser":
					// 人脸识别注册
					webSocket.OnFaceAddUser && typeof webSocket.OnFaceAddUser === "function" && webSocket.OnFaceAddUser(result);
					break;
				case "faceEditUser":
					// 人脸识别修改
					webSocket.OnFaceEditUser && typeof webSocket.OnFaceEditUser === "function" && webSocket.OnFaceEditUser(result);
					break;
				case "faceDelUser":
					// 删除人脸
					webSocket.OnFaceDelUser && typeof webSocket.OnFaceDelUser === "function" && webSocket.OnFaceDelUser(result);
					break;
				default:
					// 其他消息
					console.log("接收到其他消息" + JSON.stringify(result));
					break;
			}
		}
	};
	// 监听 WebSocket 连接关闭事件
	webSocket.socket.onclose = (res : any) => {
		console.log(webSocket.num, webSocket.maxReconnectionNum);
		console.log('webSocket连接关闭', res);
		timer && clearTimeout(timer);
		// 重新连接
		let b = setTimeout(() => {
			clearTimeout(b);
			webSocket.open();
		}, 1500)
		// if (webSocket.num < webSocket.maxReconnectionNum) {
		// 	let b = setTimeout(() => {
		// 		webSocket.num++;
		// 		webSocket.open();
		// 		clearTimeout(b);
		// 	}, 1500)
		// } else if (webSocket.onclose) {
		// 	// 达到最大重连次数还未成功，放弃
		// 	webSocket.onclose(res);
		// }
	};
	// 监听 WebSocket 错误事件
	webSocket.socket.onerror = (res : any) => {
		console.log('webSocket错误', res);
		timer && clearTimeout(timer);
		// 重新连接
		console.log(webSocket.num)
		if (webSocket.num < webSocket.maxReconnectionNum) {
			let b = setTimeout(() => {
				webSocket.num++;
				webSocket.open();
				clearTimeout(b);
			}, 1500)
		} else if (webSocket.onerror) {
			// 达到最大重连次数还未成功，放弃
			webSocket.onerror(res);
		}
	};
};

/* *
 * 发消息
 * data:{
 *     "action": "msg",
 *     "data": {
 *         "msg_id" : 2342344,
 *         "type" : 1,
 *         "to_uid" : 1,
 *         "content" : "内容" ,
 *         "ext" : ""
 *     }
 * }
 * 
 * */
webSocket.sendMessage = (params : any) => {
	// 登录中
	if (webSocket.socket) {
		let readyState = webSocket.socket.readyState;
		if (readyState == 0) {
			let a = setTimeout(() => {
				// 重新发消息
				webSocket.sendMessage(params);
				clearTimeout(a);
			}, 1000)
			return false
		}
		// 0，表示正在连接；1，表示连接成功，可以通信了；2，表示连接正在关闭；3，表示连接已经关闭，或者打开连接失败。
		if (readyState === 1) {
			if (!params.data.action) {
				console.log('请求类型错误');
				return false
			}
			console.log('webSocket发消息' + JSON.stringify(params.data));
			// 发送
			webSocket.socket.send(JSON.stringify(params.data));
			params.success && typeof params.success === "function" && params.success();
		} else if (readyState === 0 || readyState === 2) {
			let a = setTimeout(() => {
				clearTimeout(a);
				// 重新发消息
				webSocket.sendMessage(params);
			}, 500)
		} else {
			// 先去打开websocket
			webSocket.open(() => {
				// 重新发消息
				webSocket.sendMessage(params);
			});
		}
	} else {
		// 先去打开websocket
		webSocket.open(() => {
			// 重新发消息
			webSocket.sendMessage(params);
		});
	}
};

// 检查连接
webSocket.checkConnect = function () {
	timer && clearTimeout(timer);
	if (webSocket.socket) {
		let readyState = webSocket.socket.readyState;
		// 0，表示正在连接；1，表示连接成功，可以通信了；2，表示连接正在关闭；3，表示连接已经关闭，或者打开连接失败。
		if (readyState === 1) {
			if (webSocket.action.ping) {
				webSocket.sendMessage({
					data: {
						action: webSocket.action.ping
					},
					success: () => {
						timer = setTimeout(() => {
							clearTimeout(webSocket.timer);
							webSocket.checkConnect();
						}, webSocket.heartbeat); // 心跳包
					},
					fail: () => {
						webSocket.close();
					}
				});
			} else {
				console.log("websocket未配置心跳包action");
			}
		} else if (readyState === 0 || readyState === 2) {
			let b = setTimeout(() => {
				clearTimeout(b);
				// 重新发消息
				webSocket.open();
			}, 500)
		} else {
			webSocket.open();
		}
	} else {
		webSocket.open();
	}
};

// 


export default {
	webSocket
}