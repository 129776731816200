<template>
	<div>
		<el-button class="import-btn" plain type="primary" @click="ShowImportFile">
			<img class="wh-14" src="@/assets/icon_13.png" alt="">
			<span class="ml-8 fz-14 lh-36">导入</span>
		</el-button>
		<ImportFile ref="ImportFilePop" @change="emits('change')"></ImportFile>
	</div>
</template>

<script lang="ts" setup>
	import { ref, defineProps, withDefaults, defineEmits, getCurrentInstance } from 'vue';

	interface main {
		data ?: any
	}

	const props = withDefaults(defineProps<main>(), {
		data: {},
	});
	
	const { proxy } = getCurrentInstance() as any;
	const ImportFilePop = ref<any>();
	const emits = defineEmits(["change"])

	const ShowImportFile = () => {
		if (!props.data.upapi) {
			proxy.$message.error("暂未开放");
			return false;
		}
		ImportFilePop.value.open({
			name: props.data.name,
			downapi: props.data.downapi,
			downname: props.data.downname,
			upapi: props.data.upapi,
			data: props.data.data,
		});
	};
</script>

<style lang="scss" scoped>
	.import-btn {
		width: 102px;
		height: 36px;
		border-radius: 4px;
		background: transparent;
		color: #0066FF;
	}
</style>