<template>
	<el-select remote-show-suffix filterable clearable :style="{'width': `${width}`}" :disabled="disabled" :size="size"
		v-model="AreaId" :loading="loading" @focus="SelectFocus" :placeholder="placeholder" @change="AreaChange"
		@clear="AreaClear">
		<el-option v-for="v in AreaList" :key="v[ValueKey]" :label="v[LabelKey]" :value="v[ValueKey]" />
	</el-select>
</template>

<script lang="ts" setup>
	import { ref, getCurrentInstance, defineEmits, defineProps, withDefaults, onMounted, watch } from 'vue';

	interface main {
		id ?: string | number,
		size ?: string,
		ValueKey ?: string,
		LabelKey ?: string,
		width ?: string | number,
		disabled ?: boolean,
		IsAuto ?: boolean,
		WarehouseId ?: string | number,
	};

	const props = withDefaults(defineProps<main>(), {
		id: "",
		size: "default",
		ValueKey: "value",
		LabelKey: "label",
		width: "100%",
		disabled: false,
		IsAuto: false,
		WarehouseId: "",
	});

	const { proxy } = getCurrentInstance() as any;
	const emits = defineEmits(["update:id", "change", "clear"]);
	const AreaId = ref<any>("");
	const AreaList = ref<any>([]);
	const loading = ref(false);
	const placeholder = ref("请选择库区");

	watch(() => props.id, (n : any) => {
		AreaId.value = n;
	});

	onMounted(() => {
		AreaId.value = props.id;
		if (props.IsAuto) {
			if (proxy.$store.state.IsRefreshAreaList) {
				GetData();
			} else {
				if (proxy.$store.state.AreaInfo) {
					if (proxy.$store.state.AreaInfo.id == props.WarehouseId) {
						AreaList.value = proxy.$store.state.AreaInfo.list;
					} else {
						GetData();
					}
				} else {
					GetData();
				}
			}
		}
	});

	const AreaChange = (data : any) => {
		emits("update:id", data);
		emits("change", data);
	};
	const AreaClear = () => {
		emits("clear");
	};
	const SelectFocus = () => {
		if (proxy.$store.state.IsRefreshAreaList) {
			GetData();
		} else {
			if (proxy.$store.state.AreaInfo) {
				if (proxy.$store.state.AreaInfo.id == props.WarehouseId) {
					AreaList.value = proxy.$store.state.AreaInfo.list;
				} else {
					GetData();
				}
			} else {
				GetData();
			}
		}
	};
	const GetData = () => {
		loading.value = true;
		proxy.$api.tokenRequest({
			url: '/admin/warehouse/area',
			data: {
				warehouse_id: props.WarehouseId,
				sn: "",
				name: "",
				is_page: 0,
			},
			success: (res : any) => {
				loading.value = false;
				AreaList.value = res.data;
				proxy.$store.commit("GetAllList", {
					type: 6,
					id: props.WarehouseId,
					list: res.data,
				});
			},
			fail: () => {
				loading.value = false;
			},
		});
	};
</script>

<style>
</style>