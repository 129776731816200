<template>
	<el-select remote-show-suffix clearable filterable :style="{'width': `${width}`}" :disabled="disabled" :size="size"
		v-model="TypeId" :loading="loading" @focus="SelectFocus" :placeholder="placeholder"
		@change="TypeChange" @clear="TypeClear">
		<el-option v-for="v in TypeList" :key="v[ValueKey]" :label="v[LabelKey]" :value="v[ValueKey]" />
	</el-select>
</template>

<script lang="ts" setup>
	import { ref, getCurrentInstance, defineEmits, defineProps, withDefaults, onMounted, watch } from 'vue';

	interface main {
		id ?: string | number,
		size ?: string,
		ValueKey ?: string,
		LabelKey ?: string,
		width ?: string | number,
		disabled ?: boolean,
		IsAuto ?: boolean,
	};

	const props = withDefaults(defineProps<main>(), {
		id: "",
		size: "default",
		ValueKey: "value",
		LabelKey: "label",
		width: "100%",
		disabled: false,
		IsAuto: false,
	});

	const { proxy } = getCurrentInstance() as any;
	const emits = defineEmits(["update:id", "change", "clear"]);
	const TypeId = ref<any>("");
	const TypeList = ref<any>([]);
	const loading = ref(false);
	const placeholder = ref("请选择单据范围")

	watch(() => props.id, (n : any) => {
		TypeId.value = n;
	});

	onMounted(() => {
		TypeId.value = props.id;
		if (proxy.$store.state.IsRefreshInOrderType) {
			GetData();
		} else {
			if (proxy.$store.state.InOrderTypeList.length > 0) {
				TypeList.value = proxy.$store.state.InOrderTypeList;
				emits("update:id", TypeList.value[0].value);
			} else {
				GetData();
			}
		}
	});

	const TypeChange = (data : any) => {
		emits("update:id", data);
		emits("change", data);
	};
	const TypeClear = () => {
		emits("clear");
	};
	const SelectFocus = () => {
		if (proxy.$store.state.IsRefreshInOrderType) {
			GetData();
		} else {
			if (proxy.$store.state.InOrderTypeList.length > 0) {
				TypeList.value = proxy.$store.state.InOrderTypeList;
				emits("update:id", TypeList.value[0].value);
			} else {
				GetData();
			}
		}
	};
	const GetData = () => {
		loading.value = true;
		proxy.$api.tokenRequest({
			url: "/admin/common/orderType",
			method: "GET",
			success: (res : any) => {
				loading.value = false;
				TypeList.value = res.data.in;
				TypeId.value = res.data.in[0].value;
				emits("update:id", res.data.in[0].value);
				proxy.$store.commit("GetAllList", {
					type: 21,
					data: res.data.in,
				});
			},
			fail: () => {
				loading.value = false;
			}
		});
	}
</script>

<style>
</style>