import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElementPlus from './plugins/element';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import print from 'vue3-print-nb';
import './styles/element/index.scss';

// 公共样式
import './assets/css/item.css';
import './assets/css/togyUI.css';
import '../node_modules/swiper/swiper.scss';

// 全局挂载请求
import api from './api/api';
import utils from './api/utils';
import CommonApi from './api/CommonApi';
import websocket from './api/websocket';
import eventBus from './api/EventBus';
import translate from './api/translate';
import PublicView from './components/Components';

const debounce = (fn : any, delay : any) => {
	let timer = null as any
	return function () {
		const args = [] as any
		clearTimeout(timer)
		timer = setTimeout(function () {
			fn.apply(...args)
		}, delay)
	}
}

const _ResizeObserver = window.ResizeObserver
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
	constructor(callback : any) {
		callback = debounce(callback, 16)
		super(callback)
	}
}

const app = createApp(App);
installElementPlus(app);
app.config.globalProperties.$api = api;
app.config.globalProperties.$CommonApi = CommonApi;
app.config.globalProperties.$websocket = websocket.webSocket;
app.config.globalProperties.$eventBus = eventBus;
app.config.globalProperties.$utils = utils;
app.config.globalProperties.$translate = translate;
app.use(store).use(router).use(print).use(VueAwesomeSwiper).use(PublicView).mount('#app')