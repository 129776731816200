import api from "./api";

// 登录
const login = (params : any) => {
	api.request({
		url: '/admin/login',
		method: "POST",
		data: params.data,
		success: (res : any) => {
			localStorage.setItem("token", res.data.token);
			params.success && typeof params.success === 'function' && params.success(res.data);
		},
		fail: (err : any) => {
			params.fail && typeof params.fail === 'function' && params.fail(err);
		}
	});
};

// 通过了类型获取仓库各级数据
const GetByType = (params : any) => {
	return new Promise((reslove : any) => {
		api.tokenRequest({
			url: "/admin/warehouse/listByType",
			isLoading: params.isLoading ? '加载中...' : '',
			data: params.data,
			success: (res : any) => {
				reslove(res.data);
			},
		});
	});
};

// 通过字段获取仓库各级数据
const GetWarehouseData = (params : any) => {
	let url : string = "";
	let data : any = {};
	if (params.type == 1) {
		// 库区
		url = "/admin/warehouse/area";
		data = {
			warehouse_id: params.warehouse_id,
			sn: "",
			name: "",
		}
	} else if (params.type == 2) {
		// 巷道
		url = "/admin/warehouse/roadway";
		data = {
			warehouse_id: params.warehouse_id,
			sn: "",
			name: "",
		}
	} else if (params.type == 3) {
		// 库位
		url = "/admin/warehouse/location";
		data = {
			warehouse_id: params.warehouse_id,
			sn: "",
			name: "",
		}
	}
	data.is_page = 0;
	return new Promise((reslove : any) => {
		api.tokenRequest({
			url: url,
			isLoading: params.isLoading ? '加载中...' : '',
			data: data,
			success: (res : any) => {
				reslove(res.data);
			},
		});
	});
}

export default {
	login: login,
	GetByType: GetByType,
	GetWarehouseData: GetWarehouseData,
}