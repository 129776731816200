<template>
	<div>
		<el-button class="print-btn" type="primary" plain v-print="print" v-if="PrintArr.length > 0">
			<span class="fz-14 lh-36">{{text}}</span>
		</el-button>
		<el-button class="print-btn" type="primary" plain @click="StartPrint" v-else>
			<span class="fz-14 lh-36">{{text}}</span>
		</el-button>
		<div class="fixed print-box">
			<div id="printBox">
				<div class="" style="page-break-after:always" v-for="(v, i) in PrintArr" :key="i">
					<div class="title flex-row-center-sb">
						<span class="one-row flex-1">{{v.sn}}</span>
						<!-- <img class="label_logo" src="@/assets/print_logo.png" alt=""> -->
					</div>
					<img class="qrcode_1" :src="v.barcode_url" alt="">
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
	import { defineProps, ref, watch, getCurrentInstance } from 'vue';

	const props = defineProps({
		text: {
			type: String,
			default: "",
		},
		SelectArr: {
			type: Array,
			default: function () {
				return []
			},
		}
	});
	
	const { proxy } = getCurrentInstance() as any;
	const PrintArr = ref<any>([]);
	const print = ref<any>({
		id: 'printBox',//这里的id就是上面我们的打印区域id，实现指哪打哪
		popTitle: '', // 打印配置页上方的标题
		extraCss: "", // 打印可引入外部的一个 css 文件
		extraHead: ' ', // 最上方的头部文字，附加在head标签上的额外标签，使用逗号分割
		preview: false, // 是否启动预览模式，默认是false
		previewTitle: ' ', // 打印预览的标题
		previewPrintBtnLabel: ' ', // 打印预览的标题下方的按钮文本，点击可进入打印
		zIndex: 20002, // 预览窗口的z-index，默认是20002，最好比默认值更高
	});

	watch(() => props.SelectArr, (n : any) => {
		PrintArr.value = n;
	}, {
		immediate: true,
	});

	const StartPrint = () => {
		proxy.$message.error("请选择箱码");
	};
</script>

<style lang="scss" scoped>
	.print-btn {
		height: 36px;
		border-radius: 4px;
		background: transparent;
		color: #0066FF;
	}

	.print-box {
		top: -800px;
		left: -1000000px;
	}

	@media print {
		@page {
			size: auto;
			margin: 2mm !important;
		}

		#printBox {
			height: 100%;
		}
		
		#printBox .title {
			font-size: 3.2mm;
		}
		
		#printBox .label_logo {
			width: 14mm;
			height: 3.5mm;
		}

		#printBox .qrcode_1 {
			width: 100%;
			height: 14mm;
			margin-top: 1mm;
			margin-bottom: 1mm;
		}

		#printBox .sn {
			font-size: 2.5mm;
			margin-top: 2mm;
		}
	}
</style>