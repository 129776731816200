<template>
	<div>
		<el-button class="add-btn-1" type="primary" @click="Submit">{{text}}</el-button>
	</div>
</template>

<script lang="ts" setup>
	import { ref, defineEmits, getCurrentInstance } from 'vue';
	
	const { proxy } = getCurrentInstance() as any;
	const emits = defineEmits(["change"]);
	const text = ref("添加");
	
	const Submit = () => {
		emits("change");
	};
</script>

<style lang="scss" scope>
	.add-btn-1 {
		min-width: 70px;
		padding-left: 20px;
		padding-right: 20px;
		height: 36px;
		border-radius: 4px;
		font-size: 14px;
		line-height: 36px;
	}
</style>