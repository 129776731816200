<template>
	<div>
		<el-dialog v-model="pop" title="" width="504px" :show-close="false" destroy-on-close :align-center="true">
			<template #header>
				<div class="flex-row-center-sb pl-20 ptb-20">
					<div class="fz-18 co-1F2D48 font-blod">拒绝原因</div>
					<div class="pr-20 pointer" @click="close">
						<img class="wh-12" src="@/assets/icon_15.png" alt="">
					</div>
				</div>
			</template>
			<div class="fz-15 co-1F2D48 plr-18">
				<el-input class="fz-15" :autosize="{minRows: 6}" type="textarea" resize="none" v-model="remark" placeholder="请输入拒绝理由"></el-input>
			</div>
			<div class="flex-row-center-end pr-20 pb-20 pt-20">
				<el-button class="cancel-btn" @click="close">取消</el-button>
				<el-button class="sure-btn" type="primary" @click="submit">{{props.sureText}}</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script lang="ts" setup>
	import { ref, defineExpose, defineEmits, withDefaults, defineProps, getCurrentInstance } from 'vue';

	interface main {
		sureText ?: string,
	}
	
	const { proxy } = getCurrentInstance() as any;
	const props = withDefaults(defineProps<main>(), {
		sureText: "确认拒绝",
	});
	const emits = defineEmits(["change"]);
	const pop = ref(false);
	const remark = ref("");

	const submit = () => {
		if (!remark.value) {
			proxy.$message.error("请输入拒绝原因");
			return false;
		}
		emits("change", remark.value);
		close();
	};
	const open = () => {
		pop.value = true;
	};
	const close = () => {
		remark.value = "";
		pop.value = false;
	};

	defineExpose({
		open,
		close,
	});
</script>

<style lang="scss" scoped>
	@import "@/styles/publicscss/publicscss.scss";

	.cancel-btn {
		width: 80px;
		height: 36px;
		border-radius: 4px;
		font-size: 14px;
		color: #1F2D48;
		line-height: 36px;
	}

	.sure-btn {
		width: 80px;
		height: 36px;
		border-radius: 4px;
		font-size: 14px;
		color: #FFFFFF;
		line-height: 36px;
	}
</style>