<template>
	<el-cascader :size="size" :style="{'width': `${width}`}" v-model="ClassifyIdArr" :disabled="disabled" :options="options"
		:props="optionProps" separator="/" :placeholder="placeholder" clearable filterable @focus="SelectFocus"
		@clear="ClassifyClear" @change="ClassifyChange" />
</template>

<script lang="ts" setup>
	import { ref, getCurrentInstance, defineEmits, defineProps, withDefaults, onMounted, watch } from 'vue';

	interface main {
		ids ?: any,
		size ?: string,
		width ?: string | number,
		disabled ?: boolean,
		IsAuto ?: boolean,
	};

	const props = withDefaults(defineProps<main>(), {
		ids: [],
		size: "default",
		width: "100%",
		disabled: false,
		IsAuto: false,
	});

	const { proxy } = getCurrentInstance() as any;
	const emits = defineEmits(["update:ids", "change", "clear"]);
	const options = ref([]);
	const optionProps = ref({
		value: "id",
		label: "title",
		children: "children",
	});
	const ClassifyIdArr = ref<any>([]);
	const loading = ref(false);
	const placeholder = ref("请选择分类");

	watch(() => props.ids, (n : any) => {
		ClassifyIdArr.value = n;
	});

	onMounted(() => {
		ClassifyIdArr.value = props.ids;
		if (props.IsAuto) {
			if (proxy.$store.state.IsRefreshClassifyList) {
				GetData();
			} else {
				if (proxy.$store.state.ClassifyList.length > 0) {
					options.value = proxy.$store.state.ClassifyList;
				} else {
					GetData();
				}
			}
		}
	});

	const ClassifyChange = (data : any) => {
		emits("update:ids", data);
		emits("change", data);
	};
	const ClassifyClear = () => {
		emits("clear");
	};
	const SelectFocus = () => {
		if (proxy.$store.state.IsRefreshClassifyList) {
			GetData();
		} else {
			if (proxy.$store.state.ClassifyList.length > 0) {
				options.value = proxy.$store.state.ClassifyList;
			} else {
				GetData();
			}
		}
	};
	const GetData = () => {
		loading.value = true;
		proxy.$api.tokenRequest({
			url: '/admin/cate/allCate',
			success: (res : any) => {
				loading.value = false;
				options.value = res.data;
				console.log(options.value)
				proxy.$store.commit("GetAllList", {
					type: 17,
					data: res.data,
				});
			},
			fail: () => {
				loading.value = false;
			}
		});
	}
</script>

<style>
</style>