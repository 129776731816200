<template>
	<div class="main">
		<router-view></router-view>
	</div>
</template>

<script lang="ts" setup>
	import { onMounted, getCurrentInstance } from 'vue';

	const { proxy } = getCurrentInstance() as any;

	onMounted(() => {
		let href = window.location.href;
		if (href.indexOf("localhost") > -1) {
			proxy.$store.commit("SetMode", 1);
		} else {
			proxy.$store.commit("SetMode", 2);
		}
	})
</script>

<style>
	* {
		padding: 0;
		margin: 0;
	}

	.main {
		height: 100vh;
		color: #393E4D;
	}
</style>