<template>
	<div>
		<el-dialog v-model="pop" draggable width="810px" destroy-on-close :align-center="true" :show-close="false">
			<template #header>
				<div class="flex-row-center-sb pl-30 ptb-22 boderb1-EBEBED">
					<div class="fz-18 co-1F2D48 font-blod">个人信息</div>
					<div class="pr-30" @click="close">
						<img class="wh-12 pointer" src="@/assets/icon_15.png" alt="">
					</div>
				</div>
			</template>
			<el-form ref="form" size="large" :inline="true" label-position="top" :rules="rules" :model="params"
				class="plr-30 pt-30">
				<el-row>
					<el-col :span="24">
						<el-form-item prop="avatar">
							<template #label>
								<div class="fz-15 co-1F2D48">上传照片</div>
							</template>
							<div class="flex-row-end">
								<el-upload class="avatar" :action="`${config.request_host}/admin/upload`"
									:show-file-list="false" :on-success="handleAvatarSuccess"
									:before-upload="beforeAvatarUpload">
									<img v-if="params.avatar" :src="params.avatar" class="avatar" />
									<img v-else src="@/assets/icon_52.png" class="avatar" />
								</el-upload>
								<div class="ml-20">
									<div class="flex-row-center-start fz-12" style="line-height: normal;">
										<div class="point bg-0066FF"></div>
										<div class="co-949CA9 ml-6">请上传72*72px的图片</div>
									</div>
									<div class="flex-row-center-start fz-12 mt-8" style="line-height: normal;">
										<div class="point bg-0066FF"></div>
										<div class="co-949CA9 ml-6">图片大小不得超过5MB</div>
									</div>
									<div class="flex-row-center-start fz-12 mt-8" style="line-height: normal;">
										<div class="point bg-0066FF"></div>
										<div class="co-949CA9 ml-6">请上传公司LOGO</div>
									</div>
								</div>
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item prop="nickname">
							<template #label>
								<span class="screen-label">用户名称</span>
							</template>
							<el-input class="w-100" v-model="params.nickname" placeholder="请填写用户名称" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item>
							<template #label>
								<span class="screen-label">手机号码</span>
							</template>
							<el-input class="w-100" disabled v-model="params.phone" placeholder="暂无" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item>
							<template #label>
								<span class="screen-label">电子邮箱</span>
							</template>
							<el-input class="w-100" disabled v-model="params.mail" placeholder="暂无" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item>
							<template #label>
								<span class="screen-label">角色类型</span>
							</template>
							<el-input class="w-100" disabled v-model="params.auth_text" placeholder="暂无" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item>
							<template #label>
								<span class="screen-label">创建日期</span>
							</template>
							<el-input class="w-100" disabled v-model="params.create_time" placeholder="请输入创建日期" />
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<template #footer>
				<div class="flex-row-center-end">
					<el-button class="cancel-btn" @click="close">取消</el-button>
					<el-button class="save-btn" type="primary" @click="Submit">保存</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script lang="ts" setup>
	import { ref, defineExpose, defineEmits, getCurrentInstance } from 'vue';
	import config from '@/api/config';

	const { proxy } = getCurrentInstance() as any;
	const emits = defineEmits(["change"]);
	const pop = ref<any>(false);
	const form = ref<any>();
	const params = ref<any>({
		avatar: "",
		nickname: "",
		phone: "",
		mail: "",
		warehouse_id: "",
		auth_txt: "",
		time: "",
	});
	const rules = ref({
		nickname: [{
			required: true,
			message: "姓名不能为空",
			trigger: "blur",
		}],
	});
	const details = ref<any>();

	const Submit = async () => {
		if (!params.value.avatar) {
			proxy.$message.error("请上传头像");
			return false;
		}
		await form.value.validate((e : any) => {
			if (e) {
				let data : any = JSON.parse(JSON.stringify(params.value));
				proxy.$api.tokenRequest({
					url: '/admin/admin',
					isLoading: '加载中...',
					method: "PUT",
					data: {
						avatar: data.avatar,
						nickname: data.nickname,
					},
					success: () => {
						close();
						emits("change");
					},
				});
			}
		});
	};
	const beforeAvatarUpload = (rawFile : any) => {
		if (rawFile.type !== 'image/jpeg' && rawFile.type !== 'image/png' && rawFile.type !== 'image/png') {
			proxy.$message.error("图片格式只能为jpeg格式或者png格式");
			return false
		}
		return true
	};
	const handleAvatarSuccess = (data : any) => {
		params.value.avatar = data.data;
	};
	const open = () => {
		pop.value = true;
		let user : any = JSON.parse(localStorage.getItem("userInfo") as any);
		console.log(user)
		details.value = user;
		params.value.avatar = user.info.avatar;
		params.value.nickname = user.info.nickname;
		params.value.phone = user.info.phone;
		params.value.mail = user.info.mail;
		params.value.warehouse_id = user.info.warehouse_id;
		params.value.auth_text = user.info.auth_text;
		params.value.create_time = user.info.create_time;
	};
	const close = () => {
		pop.value = false;
	};

	defineExpose({
		open,
		close,
	})
</script>

<style lang="scss" scoped>
	@import "@/styles/publicscss/publicscss.scss";
	
	.cancel-btn {
		width: 100px;
		height: 40px;
		border-radius: 4px;
		font-size: 14px;
		color: #1F2D48;
		background: #FFFFFF;
		line-height: 40px;
	}

	.save-btn {
		width: 100px;
		height: 40px;
		border-radius: 4px;
		font-size: 14px;
		color: #FFFFFF;
		line-height: 40px;
	}

	.avatar {
		width: 78px;
		height: 78px;
	}

	.point {
		width: 4px;
		height: 4px;
		border-radius: 50%;
	}
</style>