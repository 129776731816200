<template>
	<el-cascader :size="size" :style="{'width': `${width}`}" v-model="AddressIdArr" :options="options"
		:props="optionProps" separator="，" :placeholder="placeholder" clearable filterable @focus="SelectFocus"
		@clear="AddressClear" @change="AddressChange" />
</template>

<script lang="ts" setup>
	import { ref, getCurrentInstance, defineEmits, defineProps, withDefaults, onMounted, watch } from 'vue';

	interface main {
		ids ?: any,
		size ?: string,
		width ?: string | number,
		disabled ?: boolean,
		IsAuto ?: boolean,
	};

	const props = withDefaults(defineProps<main>(), {
		ids: [],
		size: "default",
		width: "100%",
		disabled: false,
		IsAuto: false,
	});

	const { proxy } = getCurrentInstance() as any;
	const emits = defineEmits(["update:ids", "change", "clear"]);
	const options = ref([]);
	const optionProps = ref({
		value: "id",
		label: "name",
		children: "children",
	});
	const AddressIdArr = ref<any>([]);
	const loading = ref(false);
	const placeholder = ref("请选择地区");

	watch(() => props.ids, (n : any) => {
		AddressIdArr.value = n;
	});

	onMounted(() => {
		AddressIdArr.value = props.ids;
		if (props.IsAuto) {
			if (proxy.$store.state.AddressList.length > 0) {
				options.value = proxy.$store.state.AddressList;
			} else {
				loading.value = true;
				proxy.$api.tokenRequest({
					url: '/admin/region',
					success: (res : any) => {
						loading.value = false;
						options.value = res.data;
						proxy.$store.commit("GetAllList", {
							type: 3,
							data: res.data,
						});
					},
					fail: () => {
						loading.value = false;
					}
				});
			}
		}
	});

	const AddressChange = (data : any) => {
		emits("update:ids", data);
		emits("change", data);
	};
	const AddressClear = () => {
		emits("clear");
	};
	const SelectFocus = () => {
		if (options.value.length == 0 && !props.IsAuto) {
			if (proxy.$store.state.AddressList.length > 0) {
				options.value = proxy.$store.state.AddressList;
			} else {
				proxy.$api.tokenRequest({
					url: '/admin/region',
					data: {
						type: 1,
					},
					success: (res : any) => {
						loading.value = false;
						options.value = res.data;
						proxy.$store.commit("GetAllList", {
							type: 3,
							data: res.data,
						});
					},
					fail: () => {
						loading.value = false;
					}
				});
			}
		}
	};
</script>

<style>
</style>