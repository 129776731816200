<template>
	<div>
		<el-button class="export-btn" :style="{'height': props.height + 'px'}" type="primary" @click="SyncBtn">
			<span class="fz-14" :style="{'line-height': props.height + 'px'}">同步</span>
		</el-button>
	</div>
</template>

<script lang="ts" setup>
	import { ref, defineProps, getCurrentInstance, defineEmits } from 'vue';

	const props = defineProps({
		data: {
			type: Object,
			default: function () {
				return {}
			}
		},
		text: {
			type: String,
			default: "同步"
		},
		height: {
			type: String,
			default: "36"
		},
		mode: {
			type: Number,
			default: 1
		}
	});

	const { proxy } = getCurrentInstance() as any;
	const emits = defineEmits(["change"]);
	const loading = ref(false);
	const FileId = ref("");
	let timer : any = "";

	const SyncBtn = () => {
		if (props.mode == 1) {
			emits("change");
		} else {
			if (loading.value) {
				return false;
			}
			loading.value = true;
			proxy.$api.tokenRequest({
				url: props.data.api,
				method: "POST",
				isLoading: '加载中...',
				success: (data : any) => {
					FileId.value = data.data;
					QuerypProgress();
				},
				fail: () => {
					loading.value = false;
				},
			});
		}
	};
	const QuerypProgress = () => {
		timer && clearTimeout(timer);
		proxy.$api.tokenRequest({
			url: '/queue/progress',
			data: {
				id: FileId.value
			},
			success: (res : any) => {
				if (res.data.status == 1) {
					timer = setTimeout(() => {
						clearTimeout(timer);
						QuerypProgress();
					}, 500);
				} else if (res.data.status == 2) {
					timer = setTimeout(() => {
						clearTimeout(timer);
						QuerypProgress();
					}, 500);
				} else if (res.data.status == 3) {
					loading.value = false;
					timer && clearTimeout(timer);
					proxy.$message.success("已同步完成");
					emits("change");
					close();
				} else if (res.data.status == 4) {
					loading.value = false;
					proxy.$message.error("同步失败，请重新尝试");
				}
			},
		});
	};
</script>

<style lang="scss" scoped>
	.export-btn {
		min-width: 102px;
		border-radius: 4px;
	}
</style>