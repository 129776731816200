<template>
	<div class="ml-12">
		<el-button class="save-btn" :disabled="loading" :loading="loading" type="primary"
			@click="Submit">{{loading ? '保存中' : '保存'}}</el-button>
	</div>
</template>

<script lang="ts" setup>
	import { defineProps, withDefaults, defineEmits, getCurrentInstance } from 'vue'
	
	interface main {
		text ?: string,
		loading ?: boolean,
	};
	
	withDefaults(defineProps<main>(), {
		text: "保存",
		loading: false,
	});
	
	const { proxy } = getCurrentInstance() as any;
	const emits = defineEmits(["change"]);
	
	const Submit = () => {
		emits("change");
	};
</script>

<style lang="scss" scoped>
	.save-btn {
		width: 100px;
		height: 40px;
		border-radius: 4px;
		font-size: 14px;
		color: #FFFFFF;
		line-height: 40px;
	}
</style>