<template>
	<div class="flex-row-center-start pl-20 h-36 pointer" @click="Operate">
		<img class="wh-20" src="@/assets/icon_17.png" alt="">
		<span class="fz-14 color-primary ml-8">刷新</span>
	</div>
</template>

<script lang="ts" setup>
	import { defineEmits } from 'vue';
	
	const emits = defineEmits(["change"]);
	
	const Operate = () => {
		emits("change");
	};
</script>

<style>
</style>