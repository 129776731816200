<template>
	<el-select remote-show-suffix filterable :clearable="props.clearable" :style="{'width': `${width}`}" :disabled="disabled" :size="size"
		v-model="StatusId" :placeholder="placeholder" @change="StatusChange" @clear="StatusClear">
		<el-option v-for="v in props.list" :key="v[ValueKey]" :label="v[LabelKey]" :value="v[ValueKey]" />
	</el-select>
</template>

<script lang="ts" setup>
	import { ref, defineEmits, defineProps, withDefaults, onMounted, watch } from 'vue';

	interface main {
		id ?: string | number,
		size ?: string,
		placeholder ?: string,
		ValueKey ?: string,
		LabelKey ?: string,
		width ?: string | number,
		disabled ?: boolean,
		list ?: any,
		clearable ?: boolean,
	};

	const props = withDefaults(defineProps<main>(), {
		id: "",
		size: "default",
		placeholder: "请选择",
		ValueKey: "value",
		LabelKey: "label",
		width: "100%",
		disabled: false,
		list: [],
		clearable: true,
	});

	const emits = defineEmits(["update:id", "change", "clear"]);
	const StatusId = ref<any>("");

	watch(() => props.id, (n : any) => {
		StatusId.value = n;
	});

	onMounted(() => {
		StatusId.value = props.id;
	});

	const StatusChange = (data : any) => {
		emits("update:id", data);
		emits("change", data);
	};
	const StatusClear = () => {
		emits("clear");
	};
</script>

<style>
</style>