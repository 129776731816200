import { App } from 'vue'
import PageTitle from '@/components/PageTitle.vue';
import TemplateDownloadBtn from '@/components/TemplateDownloadBtn.vue';
import ImportBtn from '@/components/ImportBtn.vue';
import ExportBtn from '@/components/ExportBtn.vue';
import ListDel from '@/components/ListDel.vue';
import PagingNum from '@/components/PagingNum.vue';
import TipsPop from '@/components/TipsPop.vue';
import AddBtn from '@/components/AddBtn.vue';
import PrintWarehouseLocationLabelBtn from '@/components/PrintWarehouseLocationLabelBtn.vue';
import PrintGoodLabelBtn from '@/components/PrintGoodLabelBtn.vue';
import ListRefresh from '@/components/ListRefresh.vue';
import ImportFile from '@/components/ImportFile.vue';
import PrintOrderInfo from '@/components/PrintOrderInfo.vue';
import ReminderPop from '@/components/ReminderPop.vue';
import RejectReson from '@/components/RejectReson.vue';
import PrintBoxCode from '@/components/PrintBoxCode.vue';
import OperationBtn from '@/components/OperationBtn.vue';
import StatisticalQuantity from '@/components/StatisticalQuantity.vue';
import WarehouseSelect from '@/components/WarehouseSelect.vue';
import DisableEnableSelect from '@/components/DisableEnableSelect.vue';
import SelectModel from '@/components/SelectModel.vue';
import AddressSelect from '@/components/AddressSelect.vue';
import AreaTypeSelect from '@/components/AreaTypeSelect.vue';
import AreaSelect from '@/components/AreaSelect.vue';
import RoadwaySelect from '@/components/RoadwaySelect.vue';
import ShelvesSelect from '@/components/ShelvesSelect.vue';
import LocationTypeSelect from '@/components/LocationTypeSelect.vue';
import TrayTypeSelect from '@/components/TrayTypeSelect.vue';
import LoadingBtn from '@/components/LoadingBtn.vue';
import GoodClassifySelect from '@/components/GoodClassifySelect.vue';
import UnitSelect from '@/components/UnitSelect.vue';
import SearchBtn from '@/components/SearchBtn.vue';
import ClearBtn from '@/components/ClearBtn.vue';
import RefreshBtn from '@/components/RefreshBtn.vue';
import CancelBtn from '@/components/CancelBtn.vue';
import EditVue from '@/components/EditVue.vue';
import DeleteVue from '@/components/DeleteVue.vue';
import InOrderTypeSelect from '@/components/InOrderTypeSelect.vue';
import OutOrderTypeSelect from '@/components/OutOrderTypeSelect.vue';
import AdoptBtn from '@/components/AdoptBtn.vue';
import RefuseBtn from '@/components/RefuseBtn.vue';
import PdaUserListSelect from '@/components/PdaUserListSelect.vue';
import OperatorUserList from '@/components/OperatorUserList.vue';
import MoreOperatorUserList from '@/components/MoreOperatorUserList.vue';
import SupplierSelect from '@/components/SupplierSelect.vue';
import CreatedUserSelect from '@/components/CreatedUserSelect.vue';
import SyncBtn from '@/components/SyncBtn.vue';
import InfoPop from '@/components/InfoPop.vue';

const components = [
	{ title: 'PageTitle', componentName: PageTitle },
	{ title: 'TemplateDownloadBtn', componentName: TemplateDownloadBtn },
	{ title: 'ImportBtn', componentName: ImportBtn },
	{ title: 'ExportBtn', componentName: ExportBtn },
	{ title: 'ListDel', componentName: ListDel },
	{ title: 'PagingNum', componentName: PagingNum },
	{ title: 'TipsPop', componentName: TipsPop },
	{ title: 'AddBtn', componentName: AddBtn },
	{ title: 'PrintWarehouseLocationLabelBtn', componentName: PrintWarehouseLocationLabelBtn },
	{ title: 'PrintGoodLabelBtn', componentName: PrintGoodLabelBtn },
	{ title: 'ListRefresh', componentName: ListRefresh },
	{ title: 'ImportFile', componentName: ImportFile },
	{ title: 'PrintOrderInfo', componentName: PrintOrderInfo },
	{ title: 'ReminderPop', componentName: ReminderPop },
	{ title: 'RejectReson', componentName: RejectReson },
	{ title: 'PrintBoxCode', componentName: PrintBoxCode },
	{ title: 'OperationBtn', componentName: OperationBtn },
	{ title: 'StatisticalQuantity', componentName: StatisticalQuantity },
	{ title: 'WarehouseSelect', componentName: WarehouseSelect },
	{ title: 'DisableEnableSelect', componentName: DisableEnableSelect },
	{ title: 'SelectModel', componentName: SelectModel },
	{ title: 'AddressSelect', componentName: AddressSelect },
	{ title: 'AreaTypeSelect', componentName: AreaTypeSelect },
	{ title: 'AreaSelect', componentName: AreaSelect },
	{ title: 'RoadwaySelect', componentName: RoadwaySelect },
	{ title: 'ShelvesSelect', componentName: ShelvesSelect },
	{ title: 'LocationTypeSelect', componentName: LocationTypeSelect },
	{ title: 'TrayTypeSelect', componentName: TrayTypeSelect },
	{ title: 'LoadingBtn', componentName: LoadingBtn },
	{ title: 'GoodClassifySelect', componentName: GoodClassifySelect },
	{ title: 'UnitSelect', componentName: UnitSelect },
	{ title: 'SearchBtn', componentName: SearchBtn },
	{ title: 'ClearBtn', componentName: ClearBtn },
	{ title: 'RefreshBtn', componentName: RefreshBtn },
	{ title: 'CancelBtn', componentName: CancelBtn },
	{ title: 'EditVue', componentName: EditVue },
	{ title: 'DeleteVue', componentName: DeleteVue },
	{ title: 'InOrderTypeSelect', componentName: InOrderTypeSelect },
	{ title: 'OutOrderTypeSelect', componentName: OutOrderTypeSelect },
	{ title: 'AdoptBtn', componentName: AdoptBtn },
	{ title: 'RefuseBtn', componentName: RefuseBtn },
	{ title: 'PdaUserListSelect', componentName: PdaUserListSelect },
	{ title: 'OperatorUserList', componentName: OperatorUserList },
	{ title: 'MoreOperatorUserList', componentName: MoreOperatorUserList },
	{ title: 'SupplierSelect', componentName: SupplierSelect },
	{ title: 'CreatedUserSelect', componentName: CreatedUserSelect },
	{ title: 'SyncBtn', componentName: SyncBtn },
	{ title: 'InfoPop', componentName: InfoPop },
];

export default {
	install(app : App) {
		components.forEach(item => {
			// 通过循环遍历数据批量注册组件
			app.component(item.title, item.componentName)
		})
	}
}